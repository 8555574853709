import { createRouter, createWebHistory } from 'vue-router'
import { getSessionToken } from '../http/auth';
import store from '../store/index';
const routes = [
    {
        path: '/',
        name: 'root',
        redirect: {name: 'Login'},
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/Login.vue')
    },
    {
        path: '/forgot_password',
        name: 'Forgot Password',
        component: () => import('../views/auth/ForgotPassword.vue')
    },
    {
        path: '/reset_password',
        name: 'Reset Password',
        component: () => import('../views/auth/ResetPassword.vue')
    },
    {
        path: '/signup',
        name: 'Sign Up',
        component: () => import('../views/auth/SignUp.vue')
    },
    {
        path: '/signup_success',
        name: 'Sign Up Success',
        component: () => import('../views/auth/SignUpSuccess.vue')
    },
    {
        path: '/platform',
        name: 'Platform',
        component: () => import('../views/platform/Platform.vue'),
        redirect: {name: 'Datahub'},
        meta: {requiresAuth: true},
        beforeEnter: (to, from, next) => {
            store.dispatch('setSharing', false);
            next();
        },
        children: [
            {
                path: 'datahub',
                name: 'Datahub',
                component: () => import('../views/platform/datahub/Datahub.vue'),
                redirect: {name: 'Chat'},
                beforeEnter: (to, from, next) => {
                    let routerCapability = sessionStorage.getItem('access_level');
                    if(routerCapability > 0 && routerCapability < 1){
                        return;
                    }else{
                        next();
                    }
                },
                children: [
                    {
                        path: 'lookup',
                        name: 'Lookup',
                        component: () => import('../views/platform/datahub/lookup/Lookup.vue'),
                        redirect: {name: 'Lookup Research'},
                        beforeEnter: (to, from, next) => {
                            let routerCapability = sessionStorage.getItem('access_level');
                            if (routerCapability < 1) {
                                next({name: 'Plan'})
                            } else {
                                next();
                            }
                        },
                        children: [
                            {
                                path: 'research',
                                name: 'Lookup Research',
                                component: () => import('../views/platform/datahub/lookup/research/Research.vue'),
                            },
                            {
                                path: 'results',
                                name: 'Lookup Results',
                                component: () => import('../views/platform/datahub/lookup/results/Results.vue'),
                                redirect: {name: 'Lookup Performance'},
                                children: [
                                    {
                                        path: 'performance',
                                        name: 'Lookup Performance',
                                        component: () => import('../views/platform/performance/Performance.vue'),
                                        redirect: {name: 'Lookup Performance Sustainable'},
                                        children: [
                                            {
                                                path: 'sustainable',
                                                name: 'Lookup Performance Sustainable',
                                                component: () => import('../views/platform/performance/sustainable/Sustainable.vue'),
                                                redirect: {name: 'Lookup Performance Summary'},
                                                children: [
                                                    {
                                                        path: 'summary',
                                                        name: 'Lookup Performance Summary',
                                                        component: () => import('../views/platform/performance/sustainable/summary/Summary.vue'),
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 1) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'esg',
                                                        name: 'Lookup Performance ESG',
                                                        component: () => import('../views/platform/performance/sustainable/esg/ESG.vue'),
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 2) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                        meta: { keepAlive: true, csv: 'ESG' }
                                                    },
                                                    {
                                                        path: 'product',
                                                        name: 'Lookup Performance Product',
                                                        component: () => import('../views/platform/performance/sustainable/product/Product.vue'),
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 4) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                        meta: { keepAlive: true, csv: 'Products' }
                                                    },
                                                    {
                                                        path: 'impact',
                                                        name: 'Lookup Performance Impact',
                                                        component: () => import('../views/platform/performance/sustainable/impact/Impact.vue'),
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 3) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                        redirect: {name: 'Lookup Performance Impact Summary'},
                                                        meta: { keepAlive: true },
                                                        children: [
                                                            {
                                                                path: 'summary',
                                                                name: 'Lookup Performance Impact Summary',
                                                                component: () => import('../views/platform/performance/sustainable/impact/summary/Summary.vue'),
                                                                meta: { keepAlive: true, csv: 'Impact' }
                                                            },
                                                            {
                                                                path: 'detail',
                                                                name: 'Lookup Performance Impact Detail',
                                                                component: () => import('../views/platform/performance/sustainable/impact/detail/Detail.vue'),
                                                                meta: { keepAlive: true, csv: 'Impact' }
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        path: 'controversy',
                                                        name: 'Lookup Performance Controversy',
                                                        component: () => import('../views/platform/performance/sustainable/controversy/Controversy.vue'),
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 2) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                        meta: { keepAlive: true, csv: 'Controversy' },
                                                        redirect: {name: 'Lookup Performance Controversy Summary'},
                                                        children: [
                                                            {
                                                                path: 'summary',
                                                                name: 'Lookup Performance Controversy Summary',
                                                                component: () => import('../views/platform/performance/sustainable/controversy/summary/Summary.vue'),
                                                                meta: { keepAlive: true }
                                                            },
                                                            {
                                                                path: 'detail',
                                                                name: 'Lookup Performance Controversy Detail',
                                                                component: () => import('../views/platform/performance/sustainable/controversy/detail/Detail.vue'),
                                                                meta: { keepAlive: true }
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        path: 'sFDRPAI',
                                                        name: 'Lookup Performance SFDR - PAI',
                                                        component: () => import('../views/platform/performance/sustainable/sFDRPAI/SFDRPAI.vue'),
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 4) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                        redirect: {name: 'Lookup Performance SFDR - PAI Summary'},
                                                        meta: { keepAlive: true, /*csv: 'PAI'*/}, // remove comment here when PAI is ready 
                                                        children: [
                                                            {
                                                                path: 'summary',
                                                                name: 'Lookup Performance SFDR - PAI Summary',
                                                                component: () => import('../views/platform/performance/sustainable/sFDRPAI/summary/Summary.vue'),
                                                                meta: { keepAlive: true }
                                                            },
                                                            {
                                                                path: 'detail',
                                                                name: 'Lookup Performance SFDR - PAI Detail',
                                                                component: () => import('../views/platform/performance/sustainable/sFDRPAI/detail/Detail.vue'),
                                                                meta: { keepAlive: true }
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        path: 'eu-taxonomy',
                                                        name: 'Lookup Performance EU Taxonomy',
                                                        component: () => import('../views/platform/performance/sustainable/eUTaxonomy/EUTaxonomy.vue'),
                                                        meta: { csv: 'Taxonomy' },
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 4) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'financial',
                                                name: 'Lookup Financial Performance',
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                component: () => import('../views/platform/performance/financial/Financial.vue')
                                            },
                                        ]
                                    },
                                    {
                                        path: 'portfolio',
                                        name: 'Lookup Portfolio',
                                        component: () => import('../views/platform/portfolio/Portfolio.vue'),
                                        redirect: {name: 'Lookup Summary'},
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2 || routerCapability == 6) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                        children: [
                                            {
                                                path: 'summary',
                                                name: 'Lookup Summary',
                                                component: () => import('../views/platform/portfolio/summary/Summary.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 1) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                            },
                                            {
                                                path: 'holdings',
                                                name: 'Lookup Holdings',
                                                component: () => import('../views/platform/portfolio/holding/Holding.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true }
                                            },
                                            {
                                                path: 'assets',
                                                name: 'Lookup Assets',
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                component: () => import('../views/platform/portfolio/asset/Asset.vue')
                                            },
                                            {
                                                path: 'country',
                                                name: 'Lookup Country',
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                component: () => import('../views/platform/portfolio/country/Country.vue')
                                            },
                                            {
                                                path: 'currency',
                                                name: 'Lookup Currency',
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                component: () => import('../views/platform/portfolio/currency/Currency.vue')
                                            },
                                            {
                                                path: 'sector',
                                                name: 'Lookup Sector',
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                component: () => import('../views/platform/portfolio/sector/Sector.vue')
                                            },
                                            {
                                                path: 'sdgs',
                                                name: 'Lookup SDGs',
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                component: () => import('../views/platform/portfolio/sdgs/SDGs.vue')
                                            },
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'screening',
                        name: 'Screening',
                        component: () => import('../views/platform/datahub/screening/Screening.vue'),
                        redirect: {name: 'Fund Screening'},
                        beforeEnter: (to, from, next) => {
                            let routerCapability = sessionStorage.getItem('access_level');
                            if (routerCapability > 0 && routerCapability < 4 || routerCapability == 6) {
                                return;
                            } else {
                                next();
                            }
                        },
                        children: [
                            {
                                path: 'fund',
                                name: 'Fund Screening',
                                component: () => import('../components/platform/datahub/screening/Fund.vue'),
                                beforeEnter: (to, from, next) => {
                                    let routerCapability = sessionStorage.getItem('access_level');
                                    if (routerCapability < 4) {
                                        next({name: 'Plan'});
                                    } else {
                                        next();
                                    }
                                }
                            },
                            {
                                path: 'company',
                                name: 'Company Screening',
                                component: () => import('../components/platform/datahub/screening/Company.vue'),
                                beforeEnter: (to, from, next) => {
                                    let routerCapability = sessionStorage.getItem('access_level');
                                    if (routerCapability < 4 || routerCapability == 6 || routerCapability == 7) {
                                        next({name: 'Plan'});
                                    } else {
                                        next();
                                    }
                                },
                            },
                            {
                                path: 'country',
                                name: 'Country Screening',
                                component: () => import('../components/platform/datahub/screening/Country.vue'),
                                beforeEnter: (to, from, next) => {
                                    let routerCapability = sessionStorage.getItem('access_level');
                                    if (routerCapability < 4 || routerCapability == 6 || routerCapability == 7) {
                                        next({name: 'Plan'});
                                    } else {
                                        next();
                                    }
                                },
                            },
                        ]
                    },
                    {
                        path: 'matching',
                        name: 'Matching',
                        component: () => import('../views/platform/datahub/matching/Matching.vue'),
                        redirect:  {name: 'Matching Research'},
                        beforeEnter: (to, from, next) => {
                            let routerCapability = sessionStorage.getItem('access_level');
                            if (store.getters['profile/getAccessLevel'] == 100 || routerCapability) {
                                if (routerCapability == 100) {
                                    next();
                                }
                                else {
                                    next({name: 'Platform'});
                                }
                            }
                            else {
                                next({name: 'Platform'});
                            }
                        },
                        children: [
                            {
                                path: 'research',
                                name: 'Matching Research',
                                component: () => import('../views/platform/datahub/matching/research/Research.vue')
                            },
                            {
                                path: 'results',
                                name: 'Matching Results',
                                component: () => import('../views/platform/datahub/matching/results/Results.vue'),
                                redirect: {name: 'Matching Performance'},
                                children: [
                                    {
                                        path: 'performance',
                                        name: 'Matching Performance',
                                        component: () => import('../views/platform/performance/Performance.vue'),
                                        meta: { matching: true },
                                        children: [
                                            {
                                                path: 'sustainable',
                                                name: 'Matching Performance Sustainable',
                                                component: () => import('../views/platform/performance/sustainable/Sustainable.vue'),
                                                meta: { matching: true },
                                                redirect: {name: 'Matching Performance Summary'},
                                                children: [
                                                    {
                                                        path: 'summary',
                                                        name: 'Matching Performance Summary',
                                                        component: () => import('../views/platform/performance/sustainable/summary/Summary.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'esg',
                                                        name: 'Matching Performance ESG',
                                                        component: () => import('../views/platform/performance/sustainable/esg/ESG.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'product',
                                                        name: 'Matching Performance Product',
                                                        component: () => import('../views/platform/performance/sustainable/product/Product.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'impact',
                                                        name: 'Matching Performance Impact',
                                                        component: () => import('../views/platform/performance/sustainable/impact/Impact.vue')
                                                    },
                                                    {
                                                        path: 'controversy',
                                                        name: 'Matching Performance Controversy',
                                                        component: () => import('../views/platform/performance/sustainable/controversy/Controversy.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'sFDRPAI',
                                                        name: 'Matching Performance SFDR - PAI',
                                                        component: () => import('../views/platform/performance/sustainable/sFDRPAI/SFDRPAI.vue')
                                                    },
                                                    {
                                                        path: 'eu-taxonomy',
                                                        name: 'Matching Performance EU Taxonomy',
                                                        component: () => import('../views/platform/performance/sustainable/eUTaxonomy/EUTaxonomy.vue')
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'financial',
                                                name: 'Matching Financial Performance',
                                                component: () => import('../views/platform/performance/financial/Financial.vue')
                                            },
                                        ]
                                    },
                                    {
                                        path: 'portfolio',
                                        name: 'Matching Portfolio',
                                        component: () => import('../views/platform/portfolio/Portfolio.vue'),
                                        meta: { matching: true },
                                        redirect: {name: 'Matching Summary'},
                                        children: [
                                            {
                                                path: 'summary',
                                                name: 'Matching Summary',
                                                component: () => import('../views/platform/portfolio/summary/Summary.vue')
                                            },
                                            {
                                                path: 'holdings',
                                                name: 'Matching Holdings',
                                                component: () => import('../views/platform/portfolio/holding/Holding.vue'),
                                                meta: { keepAlive: true }
                                            },
                                            {
                                                path: 'assets',
                                                name: 'Matching Assets',
                                                component: () => import('../views/platform/portfolio/asset/Asset.vue')
                                            },
                                            {
                                                path: 'country',
                                                name: 'Matching Country',
                                                component: () => import('../views/platform/portfolio/country/Country.vue')
                                            },
                                            {
                                                path: 'currency',
                                                name: 'Matching Currency',
                                                component: () => import('../views/platform/portfolio/currency/Currency.vue')
                                            },
                                            {
                                                path: 'sector',
                                                name: 'Matching Sector',
                                                component: () => import('../views/platform/portfolio/sector/Sector.vue')
                                            },
                                            {
                                                path: 'sdgs',
                                                name: 'Matching SDGs',
                                                component: () => import('../views/platform/portfolio/sdgs/SDGs.vue')
                                            },
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                path: 'profile',
                name: 'Profile',
                component: () => import('../views/platform/profile/Profile.vue'),
                redirect: {name: 'Account Info'},
                children: [
                    {
                        path: 'account_info',
                        name: 'Account Info',
                        component: () => import('../views/platform/profile/AccountInfo.vue'),
                    },
                    {
                        path: 'billing',
                        name: 'billing',
                        component: () => import('../views/platform/profile/Billing.vue'),
                        beforeEnter: async (to, from, next) => {
                            if (store.getters['profile/getAccessLevel'] === 4 || store.getters['profile/getAccessLevel'] === 5 ) {
                                next({name: 'Account Info'});
                            } else {
                                next();
                            }
                        },
                        children:[
                            {
                                path: 'checkout/:plan/:period?',
                                name: 'Checkout',
                                component: () => import('../views/platform/profile/billing/Checkout.vue'),
                                meta: { keepAlive: true }
                            },
                            {
                                path: 'information',
                                name: 'Billing Information',
                                component: () => import('../views/platform/profile/billing/Information.vue'),
                                meta: { keepAlive: true }
                            },
                            {
                                path: 'history',
                                name: 'Billing History',
                                component: () => import('../views/platform/profile/billing/History.vue'),
                                meta: { keepAlive: true }
                            }
                        ],

                    },
                    {
                        path: 'Plan',
                        name: 'Plan',
                        component: () => import('../views/platform/profile/Plan.vue'),
                        beforeEnter: async (to, from, next) => {
                            if (store.getters['profile/getAccessLevel'] === 4 || store.getters['profile/getAccessLevel'] === 5 ) {
                                next({name: 'Account Info'});
                            } else {
                                next();
                            }
                        },

                    },
                ]
            },
            {
                path: 'customization',
                name: 'Customization',
                component: () => import('../views/platform/customization/Customization.vue'),
                redirect: {name: 'Company Info'},
                children: [
                    {
                        path: 'company_info',
                        name: 'Company Info',
                        component: () => import('../views/platform/customization/CompanyInfo.vue')
                    },
                    {
                        path: 'api',
                        name: 'api',
                        component: () => import('../views/platform/customization/Api.vue')
                    },
                    {
                        path: 'scoring',
                        name: 'scoring',
                        component: () => import('../views/platform/customization/Scoring.vue')
                    },
                    {
                        path: 'impact',
                        name: 'Impact Customization',
                        component: () => import('../views/platform/customization/Impact.vue')
                    },
                    {
                        path: 'notifications',
                        name: 'Notifications',
                        component: () => import('../views/platform/customization/Notifications.vue')
                    }
                ]
            },
            {
                path: 'portfoliohub',
                name: 'Portfolio Hub',
                component: () => import('../views/platform/portfoliohub/PortfolioHub.vue'),
                redirect: {name: 'Clients'},
                beforeEnter: (to, from, next) => {
                    let routerCapability = sessionStorage.getItem('access_level');
                    if (routerCapability > 0 && routerCapability < 2 || routerCapability == 6) {
                        return
                    } else {
                        next();
                    }
                },
                children: [
                    {
                        path: 'clients',
                        name: 'Clients',
                        component: () => import('../views/platform/portfoliohub/Clients.vue'),
                        beforeEnter: (to, from, next) => {
                            let routerCapability = sessionStorage.getItem('access_level');
                            if (routerCapability < 2) {
                                next({name: 'Plan'})
                            } else {
                                next();
                            }
                        },
                    },
                    {
                        path: 'add_client',
                        name: 'Add Client',
                        component: () => import('../views/platform/portfoliohub/add-client/AddClient.vue')
                    },
                    {
                        path: 'client/:id',
                        name: 'Client',
                        component: () => import('../views/platform/portfoliohub/client/Client.vue'),
                        redirect: {name: 'Information'},
                        beforeEnter: (to, from, next) => {
                            if (store.getters['portfolioHub/getSelectedClient'].id !== '') {
                                next();
                            }
                            else {
                                next({name: 'Portfolio Hub'});
                            }
                        },
                        children: [
                            {
                                path: 'information',
                                name: 'Information',
                                component: () => import('../views/platform/portfoliohub/client/information/Information.vue'),
                            },
                            {
                                path: 'performance',
                                name: 'Performance',
                                component: () => import('../views/platform/performance/Performance.vue'),
                                children: [
                                    {
                                        path: 'sustainable',
                                        name: 'Performance Sustainable',
                                        component: () => import('../views/platform/performance/sustainable/Sustainable.vue'),
                                        redirect: {name: 'Performance Summary'},
                                        children: [
                                            {
                                                path: 'summary',
                                                name: 'Performance Summary',
                                                component: () => import('../views/platform/performance/sustainable/summary/Summary.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 1) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true },
                                            },
                                            {
                                                path: 'esg',
                                                name: 'Performance ESG',
                                                component: () => import('../views/platform/performance/sustainable/esg/ESG.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true }
                                            },
                                            {
                                                path: 'product',
                                                name: 'Performance Product',
                                                component: () => import('../views/platform/performance/sustainable/product/Product.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 4 || routerCapability == 7) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true }
                                            },
                                            {
                                                path: 'impact',
                                                name: 'Performance Impact',
                                                component: () => import('../views/platform/performance/sustainable/impact/Impact.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 3) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true },
                                                redirect: {name: 'Performance Impact Summary'},
                                                children: [
                                                    {
                                                        path: 'summary',
                                                        name: 'Performance Impact Summary',
                                                        component: () => import('../views/platform/performance/sustainable/impact/summary/Summary.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'detail',
                                                        name: 'Performance Impact Detail',
                                                        component: () => import('../views/platform/performance/sustainable/impact/detail/Detail.vue'),
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability < 4 || routerCapability == 7) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                        meta: { keepAlive: true }
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'controversy',
                                                name: 'Performance Controversy',
                                                component: () => import('../views/platform/performance/sustainable/controversy/Controversy.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 2) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true },
                                                redirect: {name: 'Performance Controversy Summary'},
                                                children: [
                                                    {
                                                        path: 'summary',
                                                        name: 'Performance Controversy Summary',
                                                        component: () => import('../views/platform/performance/sustainable/controversy/summary/Summary.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'detail',
                                                        name: 'Performance Controversy Detail',
                                                        component: () => import('../views/platform/performance/sustainable/controversy/detail/Detail.vue'),
                                                        meta: { keepAlive: true },
                                                        beforeEnter: (to, from, next) => {
                                                            let routerCapability = sessionStorage.getItem('access_level');
                                                            if (routerCapability == 7) {
                                                                next({name: 'Plan'})
                                                            } else {
                                                                next();
                                                            }
                                                        },
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'sFDRPAI',
                                                name: 'Performance SFDR - PAI',
                                                component: () => import('../views/platform/performance/sustainable/sFDRPAI/SFDRPAI.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 4 || routerCapability == 7) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true },
                                                redirect: {name: 'Performance SFDR - PAI Summary'},
                                                children: [
                                                    {
                                                        path: 'summary',
                                                        name: 'Performance SFDR - PAI Summary',
                                                        component: () => import('../views/platform/performance/sustainable/sFDRPAI/summary/Summary.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                    {
                                                        path: 'detail',
                                                        name: 'Performance SFDR - PAI Detail',
                                                        component: () => import('../views/platform/performance/sustainable/sFDRPAI/detail/Detail.vue'),
                                                        meta: { keepAlive: true }
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'eUTaxonomy',
                                                name: 'Performance EU Taxonomy',
                                                component: () => import('../views/platform/performance/sustainable/eUTaxonomy/EUTaxonomy.vue'),
                                                beforeEnter: (to, from, next) => {
                                                    let routerCapability = sessionStorage.getItem('access_level');
                                                    if (routerCapability < 4 || routerCapability == 7) {
                                                        next({name: 'Plan'})
                                                    } else {
                                                        next();
                                                    }
                                                },
                                                meta: { keepAlive: true }
                                            },
                                        ]
                                    },
                                    {
                                        path: 'financial',
                                        name: 'Financial Performance',
                                        component: () => import('../views/platform/performance/financial/Financial.vue'),
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2 || routerCapability == 7) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'portfolio',
                                name: 'Portfolio',
                                component: () => import('../views/platform/portfolio/Portfolio.vue'),
                                redirect: {name: 'Portfolio Summary'},
                                beforeEnter: (to, from, next) => {
                                    let routerCapability = sessionStorage.getItem('access_level');
                                    if (routerCapability < 1) {
                                        next({name: 'Plan'})
                                    } else {
                                        next();
                                    }
                                },
                                children: [
                                    {
                                        path: 'summary',
                                        name: 'Portfolio Summary',
                                        component: () => import('../views/platform/portfolio/summary/Summary.vue'),
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 1 ) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                    {
                                        path: 'holdings',
                                        name: 'Portfolio Holdings',
                                        component: () => import('../views/platform/portfolio/holding/Holding.vue'),
                                        meta: { keepAlive: true },
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                    {
                                        path: 'assets',
                                        name: 'Portfolio Assets',
                                        component: () => import('../views/platform/portfolio/asset/Asset.vue'),
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                    {
                                        path: 'country',
                                        name: 'Portfolio Country',
                                        component: () => import('../views/platform/portfolio/country/Country.vue'),
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                    {
                                        path: 'currency',
                                        name: 'Portfolio Currency',
                                        component: () => import('../views/platform/portfolio/currency/Currency.vue'),
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                    {
                                        path: 'sector',
                                        name: 'Portfolio Sector',
                                        component: () => import('../views/platform/portfolio/sector/Sector.vue'),
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                    {
                                        path: 'sdgs',
                                        name: 'Portfolio SDGs',
                                        component: () => import('../views/platform/portfolio/sdgs/SDGs.vue'),
                                        beforeEnter: (to, from, next) => {
                                            let routerCapability = sessionStorage.getItem('access_level');
                                            if (routerCapability < 2) {
                                                next({name: 'Plan'})
                                            } else {
                                                next();
                                            }
                                        },
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'documentation',
                name: 'Documentation',
                component: () => import('../views/platform/documentation/Documentation.vue'),
                redirect: {name: 'Company Info'},
                children: [
                    {
                        path: 'methodologies',
                        name: 'Methodologies',
                        component: () => import('../views/platform/documentation/Methodologies.vue')
                    },
                    {
                        path: 'Indicators',
                        name: 'Indicators',
                        component: () => import('../views/platform/documentation/Indicators.vue')
                    },
                    {
                        path: 'videos',
                        name: 'Videos',
                        component: () => import('../views/platform/documentation/Videos.vue')
                    },
                ]
            },
            {
                path: 'chat',
                name: 'Chat',
                component: () => import('../views/platform/chat/Chat.vue'),
            },
        ]
    },
    {
        path: '/shareClient/:id',
        name: 'Share Client',
        component: () => import('../views/shareClient/ShareClient.vue'),
        meta: {requiresAuth: false},
        redirect: {name: 'Share Performance Summary'},
        beforeEnter: (to, from, next) => {
            store.dispatch('setSharing', true);
            next();
        },
        children: [
            {
                path: 'performance',
                name: 'Share Performance',
                component: () => import('../views/shareClient/performance/Performance.vue'),
                children: [
                    {
                        path: 'sustainable',
                        name: 'Share Performance Sustainable',
                        component: () => import('../views/shareClient/performance/sustainable/Sustainable.vue'),
                        redirect: {name: 'Share Performance Summary'},
                        children: [
                            {
                                path: 'summary',
                                name: 'Share Performance Summary',
                                component: () => import('../views/shareClient/performance/sustainable/summary/Summary.vue'),
                                meta: { keepAlive: true }
                            },
                            {
                                path: 'esg',
                                name: 'Share Performance ESG',
                                component: () => import('../views/shareClient/performance/sustainable/esg/ESG.vue'),
                                meta: { keepAlive: true }
                            },
                            {
                                path: 'product',
                                name: 'Share Performance Product',
                                component: () => import('../views/shareClient/performance/sustainable/product/Product.vue'),
                                meta: { keepAlive: true }
                            },
                            {
                                path: 'impact',
                                name: 'Share Performance Impact',
                                component: () => import('../views/shareClient/performance/sustainable/impact/Impact.vue'),
                                meta: { keepAlive: true },
                                redirect: {name: 'Share Performance Impact Summary'},
                                children: [
                                    {
                                        path: 'summary',
                                        name: 'Share Performance Impact Summary',
                                        component: () => import('../views/shareClient/performance/sustainable/impact/summary/Summary.vue'),
                                        meta: { keepAlive: true }
                                    },
                                    {
                                        path: 'detail',
                                        name: 'Share Performance Impact Detail',
                                        component: () => import('../views/shareClient/performance/sustainable/impact/detail/Detail.vue'),
                                        meta: { keepAlive: true },
                                    },
                                ]
                            },
                            {
                                path: 'controversy',
                                name: 'Share Performance Controversy',
                                component: () => import('../views/shareClient/performance/sustainable/controversy/Controversy.vue'),
                                meta: { keepAlive: true },
                                redirect: {name: 'Share Performance Controversy Summary'},
                                children: [
                                    {
                                        path: 'summary',
                                        name: 'Share Performance Controversy Summary',
                                        component: () => import('../views/shareClient/performance/sustainable/controversy/summary/Summary.vue'),
                                        meta: { keepAlive: true }
                                    },
                                    {
                                    path: 'detail',
                                        name: 'Share Performance Controversy Detail',
                                        component: () => import('../views/shareClient/performance/sustainable/controversy/detail/Detail.vue'),
                                        meta: { keepAlive: true }
                                    },
                               ]
                            },
                            {
                                path: 'sFDRPAI',
                                name: 'Share Performance SFDR - PAI',
                                component: () => import('../views/shareClient/performance/sustainable/sFDRPAI/SFDRPAI.vue'),
                                meta: { keepAlive: true },
                                redirect: {name: 'Share Performance SFDR - PAI Summary'},
                                children: [
                                    {
                                        path: 'summary',
                                        name: 'Share Performance SFDR - PAI Summary',
                                        component: () => import('../views/shareClient/performance/sustainable/sFDRPAI/summary/Summary.vue'),
                                        meta: { keepAlive: true }
                                    },
                                    {
                                        path: 'detail',
                                        name: 'Share Performance SFDR - PAI Detail',
                                        component: () => import('../views/shareClient/performance/sustainable/sFDRPAI/detail/Detail.vue'),
                                        meta: { keepAlive: true }
                                    },
                                ]
                            },
                            {
                                path: 'eUTaxonomy',
                                name: 'Share Performance EU Taxonomy',
                                component: () => import('../views/shareClient/performance/sustainable/eUTaxonomy/EUTaxonomy.vue'),
                                meta: { keepAlive: true },
                            },
                        ]
                    },
                    {
                        path: 'financial',
                        name: 'Share Financial Performance',
                        component: () => import('../views/shareClient/performance/financial/Financial.vue'),
                    },
                ]
            },
            {
                path: 'portfolio',
                name: 'Share Portfolio',
                component: () => import('../views/shareClient/portfolio/Portfolio.vue'),
                redirect: {name: 'Share Portfolio Summary'},
                children: [
                    {
                        path: 'summary',
                        name: 'Share Portfolio Summary',
                        component: () => import('../views/shareClient/portfolio/summary/Summary.vue')
                    },
                    {
                        path: 'holdings',
                        name: 'Share Portfolio Holdings',
                        component: () => import('../views/shareClient/portfolio/holding/Holding.vue'),
                        meta: { keepAlive: true }
                    },
                    {
                        path: 'assets',
                        name: 'Share Portfolio Assets',
                        component: () => import('../views/shareClient/portfolio/asset/Asset.vue')
                    },
                    {
                        path: 'country',
                        name: 'Share Portfolio Country',
                        component: () => import('../views/shareClient/portfolio/country/Country.vue')
                    },
                    {
                        path: 'currency',
                        name: 'Share Portfolio Currency',
                        component: () => import('../views/shareClient/portfolio/currency/Currency.vue')
                    },
                    {
                        path: 'sector',
                        name: 'Share Portfolio Sector',
                        component: () => import('../views/shareClient/portfolio/sector/Sector.vue')
                    },
                    {
                        path: 'sdgs',
                        name: 'Share Portfolio SDGs',
                        component: () => import('../views/shareClient/portfolio/sdgs/SDGs.vue')
                    },
                ]
            }
        ]
    },
    {
        path: '/legal',
        name: 'Legal',
        component: () => import('../views/public/Legal.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => { 
    if (to.matched.some(record => record.meta.requiresAuth)) { 
        if (await getSessionToken()) {
            next()
        }
        else {
            next('/login')
        }
    }else { 
        next() // make sure to always call next()! 
    } 
});

export default router
