import {getSessionToken, getAccessLevel} from '../http/auth';
import {API} from '../http/api';
import router from "../router";
import avatarPlaceholderPath from '@/assets/images/avatar-placeholder.png';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();
const profile = {
    namespaced: true,
    state: {
        userData: {
            FirstName: '',
            LastName: '',
            Dob: '',
            Email: '',
            CellPhone: '',
            Country: '',
            State: '',
            City: '',
            Address: '',
            Address2: '',
            Zip: '',
            S3KeyForPhoto: '',
            CognitoId: '',
            VAT: '',
            UserLanguage: [],
        },
        userImg: '',
        companyData: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            color: '',
            email: '',
            facebookUrl: '',
            id: '',
            instagramUrl: '',
            legalName: '',
            linkedinUrl: '',
            logo: null,
            phone: '',
            state: '',
            twitterUrl: '',
            website: '',
            zipCode: '',
        },
        loadingProfileData: false,
        loadingCompanyData: false,
        updatingProfileData: false,
        deletingAccount: false,
        accountDeleted: false,
        loadingUserImg: false,
        accessLevel: 0,
        userCapability: -1,
        loadingCompanyLogo: false
    },
    mutations: {
        setUserData(state, payload) {
            state.userData = payload;
        },
        setLoadingProfileData(state, payload) {
            state.loadingProfileData = payload;
        },
        setUpdatingProfileData(state, payload) {
            state.updatingProfileData = payload;
        },
        setUserImg(state, payload) {
            state.userImg = payload;
        },
        setLoadingUserImg(state, payload) {
            state.loadingUserImg = payload;
        },
        setCompanyData(state, payload) {
            state.companyData = payload;
        },
        setDeletingAccount(state, payload) {
            state.deletingAccount = payload;
        },
        setAccountDeleted(state, payload) {
            state.accountDeleted = payload;
        },
        setUserCapability(state, payload) {
            state.userCapability = payload ? payload : 0;
        },
        setAccessLevel(state, payload) {
            state.accessLevel = payload;
        },
        setCompanyDataID(state, payload) {
            state.companyData.id = payload
        },
        setLoadingCompanyLogo(state, payload) {
            state.loadingCompanyLogo = payload;
        },
        setCompanyLogo(state, payload) {
            state.companyData.logo = payload;
        },
        setLoadingCompanyData(state, payload) {
            state.loadingCompanyData = payload;
        }
    },
    actions: {
        async getUserProfile({commit, dispatch}) {
            commit('setLoadingProfileData', true);
            let accessLevel = await getAccessLevel();
            commit('setUserCapability', accessLevel);
            commit('setAccessLevel', accessLevel);
            API.get(
                'getUserProfile',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(async data => {
                const userData = data;
                commit('setUserData', userData);
                if(userData.CognitoId){
                    commit('setCompanyDataID', userData.CognitoId);
                }
                if (userData.S3KeyForPhoto) {
                    API.get('getProfileImg', `${userData.S3KeyForPhoto}`, {
                        responseType: 'text',
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }).then((responseS3) => {
                        commit('setUserImg', responseS3)
                        commit('setLoadingProfileData', false);
                    }).catch((err) => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            console.log(err);
                            toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                            commit('setLoadingProfileData', false);    
                        }
                    });
                }
                else {
                    commit('setUserImg', avatarPlaceholderPath)
                    commit('setLoadingProfileData', false);
                }
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setLoadingProfileData', false);
                    throw err;    
                }
            })
        },
        async updateUserProfile({commit, dispatch}, data) {
            commit('setUpdatingProfileData', true);
            API.put(
                'updateUserProfile',
                '',
                {
                    body: data,
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(() => {
                commit('setUserData', data);
                commit('setUpdatingProfileData', false);
                toast.success(i18n.global.t('alert.success.changesSaved'));
            }).catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setUpdatingProfileData', false);    
                }
            })
        },
        async getUserCompany({commit, dispatch}) {
            commit('setLoadingCompanyData', true);
            API.get(
                'getAdvisorCompanyInfo',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(data => {
                if (data && data != '') {
                    if (data.color == null) {
                        data.color = '#00A0FF';
                    }
                    commit('setCompanyData', data);
                }
                commit('setLoadingCompanyData', false);
            }).catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setLoadingCompanyData', false);    
                }
            });
        },
        async updateUserCompany({commit, dispatch}, data) {
            commit('setUpdatingProfileData', true);
            API.post(
                'saveAdvisorCompanyInfo',
                '',
                {
                    body: {...data},
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(res => {
                if (res && res !== '') {
                    let logo = JSON.parse(JSON.stringify(this.getters["profile/getUserCompanyData"].logo));
                    commit('setCompanyData', res);
                    commit('setCompanyLogo', logo);
                    toast.success(i18n.global.t('alert.success.changesSaved'));    
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                }
                commit('setUpdatingProfileData', false);
            }).catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setUpdatingProfileData', false);    
                }
            })
        },
        async deleteAccount({commit, dispatch}) {
            commit('setDeletingAccount', true);
            API.post(
                'deleteAccount',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(() => {
                commit('setAccountDeleted', true);
                commit('setDeletingAccount', false);
                setTimeout(() => {
                    router.push({
                        name: 'root'
                    });
                    commit('setAccountDeleted', false);
                }, 250);
            }).catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    commit('setDeletingAccount', false);    
                }
            })
        },
        async uploadUserImg({commit, state, dispatch}, data) {
            commit('setLoadingUserImg', true);
            API.post(
                'imageUpload',
                '',
                {
                    body: data,
                    headers: {
                        Authorization: await getSessionToken()
                    },
                }
            )
                .then(async () => {
                    API.get('getProfileImg', `${state.userData.S3KeyForPhoto}`, {
                        responseType: 'text',
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }).then((responseS3) => {
                        commit('setUserImg', responseS3);
                        toast.success(i18n.global.t('alert.success.changesSaved'));
                        commit('setLoadingUserImg', false);
                    }).catch((err) => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            commit('setLoadingUserImg', false);
                            throw err;    
                        }
                    });
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit('setLoadingUserImg', false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));    
                    }
                })
        },
        logout({commit}) {
            commit('setUserImg', avatarPlaceholderPath)
            commit('setUserData', {})
        },
        setUserCapability({commit}, data){
            commit('setUserCapability', data)
        },
        setAccessLevel({commit}, data) {
            commit('setAccessLevel', data)
        },
        async uploadCompanyImg({commit, dispatch}, data) {
            commit('setLoadingCompanyLogo', true);
            axios.post(`https://${process.env.VUE_APP_API_NODE}.execute-api.${process.env.VUE_APP_API_REGION}.amazonaws.com/${process.env.VUE_APP_API_STAGE}/profile/portfolio/advisor/company/setLogo/`,
                {
                    type: data.type,
                    companyData:  data.id ? {id: data.id} : {id: ''}
                },
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                })
                .then(response => {
                    axios.put(response.data.url, data.base64img).then(() => { // url public
                        if (response.data.companyData === '')
                            commit('setCompanyLogo', response.data.logo);
                        else {
                            commit('setCompanyLogo', response.data.logo);
                            commit('setCompanyDataID', response.data.companyData.id);
                        }
                        commit('setLoadingCompanyLogo', false);
                        toast.success(i18n.global.t('alert.success.changesSaved'));
                    }).catch(() => {
                        commit('setLoadingCompanyLogo', false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    });
                }).catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit('setLoadingCompanyLogo', false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));    
                    }
                })
        },
    },
    getters: {
        getUserData: state => {
            return state.userData;
        },
        getUserCompanyData: state => {
            return state.companyData
        },
        getUserFirstName: state => {
            return state.userData.FirstName;
        },
        getLoadingProfileData: state => {
            return state.loadingProfileData;
        },
        getUpdatingProfileData: state => {
            return state.updatingProfileData;
        },
        getUserFullName: state => {
            return state.userData.FirstName + ' ' + state.userData.LastName;
        },
        getUserImg: state => {
            return state.userImg;
        },
        getAdvisorId: state => {
            return state.userData.CognitoId;
        },
        getDeletingAccount: state => {
            return state.deletingAccount;
        },
        getAccountDeleted: state => {
            return state.accountDeleted;
        },
        getUserEmail: state => {
            return state.userData.Email;
        },
        getLoadingUserImg: state => {
            return state.loadingUserImg;
        },
        getUserCapability: state => {
            return state.userCapability
        },
        getAccessLevel: state => {
            return state.accessLevel
        },
        getLoadingCompanyLogo: state => {
            return state.loadingCompanyLogo;
        },
        getLoadingCompanyData: state => {
            return state.loadingCompanyData;
        }
    }
}

export default profile