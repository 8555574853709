<template>
    <div id="header" class="card d-flex flex-row align-items-center mb-1 px-lg-5">
        <div class="w-100 h-100">
            <nav class="navbar navbar-expand-lg navbar-light bg-transparent p-0 h-100">
                <a class="navbar-brand d-flex align-items-center position-relative ms-2 ms-lg-0" href="https://physisinvestment.com">
                    <img src="@/assets/images/physis-logo-blue.webp" onerror="this.src='@/assets/images/physis-logo-blue.png'" class="logo" alt="Physis Investment Logo">
                </a>
                <div class="d-flex flex-row ms-auto">
                    <button class="navbar-toggler border-light ms-auto" type="button"
                        data-bs-toggle="collapse" data-bs-target="#navbar-menu"
                        aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon "></span>
                    </button>
                </div>
                
                <NavLinks/>
            </nav>
        </div>
    </div>
</template>

<script>
import NavLinks from '@/components/layout/header/NavLinks.vue';
export default {
    components: {
        NavLinks,
    },
}
</script>

<style lang="scss" scoped>

#header {
    min-height: 64px;
    height: fit-content;
    // box-shadow: 2px 24px 20px rgba(0, 0, 0, 0.08);
    .logo {
        width: 112px; 
        height: auto;
    }
    .navbar-toggler {
        border: none;
        &:focus {
            box-shadow: none;
        }
        .navbar-toggler-icon {
            background-image: url('../../../assets/images/burger.png');
            background-repeat: no-repeat;
            background-size: auto;
        }
    }

    @media screen and (max-width: 1280px) and (max-height: 600px) {
        min-height: 68px;
    }
}

</style>