<template>
    <div id="footer" :class="[isUserLogged ? 'py-2 compact' : 'py-5']" class="blue-gradient position-relative px-3 px-md-4 px-md-0 px-lg-5">
        <div class="w-100 h-100">
            <div id="supportCTA-wrapper" v-if="isUserLogged" class="position-absolute d-flex align-items-center d-lg-none" @click="contactSupport()">
                <i class="fas pointer icon fa-question-circle"></i>
                <span class="poppins-semi-bold ms-1">{{$t("content.help")}}</span>
            </div>
            <div class="d-flex flex-row flex-wrap" :class="[isUserLogged ? 'h-100 justify-content-end' : '']">
                <div class="col-12 col-md-3 mt-4 mt-md-0 logo-container-desktop" v-if="!isUserLogged">
                    <div class="mb-3">
                        <a class="d-flex align-items-center ">
                            <img class="logo" src="@/assets/images/physis-logo.webp" onerror="this.src='@/assets/images/physis-logo.png'" alt="Physis Investment Logo">
                        </a>
                    </div>
                    <p class="poppins-light">{{$t('content.physisRights', {year: currentYear})}}</p>
                </div>

                <WebsiteSection v-if="!isUserLogged"/>
                <SupportSection v-if="!isUserLogged"/>
                <SocialsSection v-if="!isUserLogged"/>
                <TermsSection :userLogged="isUserLogged"/>
                
                <div class="col-12 col-md-3 mt-4 mt-md-0 logo-container-mobile" v-if="!isUserLogged && userCapability > 0">
                    <div class="mb-3">
                        <a class="d-flex align-items-center ">
                            <img class="logo" src="@/assets/images/physis-logo.webp" onerror="this.src='@/assets/images/physis-logo.png'" alt="Physis Investment Logo">
                        </a>
                    </div>
                    <p class="poppins-light">{{$t('content.physisRights', {year: currentYear})}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WebsiteSection from '@/components/layout/footer/WebsiteSection.vue';
import SupportSection from '@/components/layout/footer/SupportSection.vue';
import SocialsSection from '@/components/layout/footer/SocialsSection.vue';
import TermsSection from '@/components/layout/footer/TermsSection.vue';

export default {
    data() {
        return {

        }
    },
    components: {
        WebsiteSection,
        SupportSection,
        SocialsSection,
        TermsSection,
    },
    computed: {
        isUserLogged() {
            if(this.$store.getters.getSharing){
              return true
            }
            let userData = this.$store.getters['profile/getUserData'];
            if (userData && userData.CognitoId) {
                return true
            }
            else {
                return false
            }
        },
        userCapability() {
          return this.$store.getters['profile/getUserCapability'];
        },
        currentYear() {
            return new Date().getFullYear();
        }
    },
    methods: {
        contactSupport(){
            window.open(this.supportURL, '_blank').focus();
        },
    }        
}
</script>

<style lang="scss" scoped>

#footer {
    color: #fff;
    :deep(.footer-link) {
        font-size: 0.875rem;
        color: #fff;
        text-decoration: none;
        &:hover {
            color: #b3dcfa;
        }
    }
    :deep(.underliner) {
        font-size: 0.85rem;
        color: #fff;
        margin-bottom: 1rem;
        position: relative;
        &::after {
            height: 1px;
            width: 34px;
            background: #ffffff;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateX(0);
        }
    }
    .logo-container-desktop, .logo-container-mobile {
        .logo {
            margin: 0 0 0 -12px; 
            height: auto; 
            width: 10rem;
        }
        p {
            color: #fff;
            font-size: 0.85rem;
            opacity: 0.48;
        }
    }
    &.compact {
        height: 36px;
        max-height: 36px;
    }
    #supportCTA-wrapper {
        font-size: 0.875rem;
        .icon {
            font-size: 1.35rem;
        }
    }
    @media screen and (max-width: 1280px) and (max-height: 600px) {
        &.compact {
            height: 60px;
            max-height: 60px;
        }
    }
    @media screen and (max-width: 991px) {
        .logo-container-desktop {
            display: none;
        }
    }
    @media screen and (min-width: 992px) {
        .logo-container-mobile {
            display: none;
        }
    }
    @media screen and (max-width: 360px){
      &.compact{
        padding-left: 8px!important;
        padding-right: 8px!important;
      }
    }
}

</style>