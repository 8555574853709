import { getSessionToken } from "../http/auth";
import { API } from "../http/api";
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';


const toast = useToast();

const customization = {
    namespaced: true,
    state: {
        loadingImpact: false,
        loadingImpactConfig: false,
        impactIndicators: [],
        impactConfiguration: [],
        pendingImpactConfig: false
    },
    mutations: {
        setLoadingImpact(state, payload) {
            state.loading = payload;
        },
        setImpactIndicators(state, payload) {
            state.impactIndicators = payload;
        },
        setLoadingImpactConfig(state, payload) {
            state.loadingImpactConfig = payload;
        },
        setImpactConfiguration(state, payload) {
            state.impactConfiguration = payload;
        },
        setSavingImpactIndicators(state, payload) {
            state.savingImpactIndicators = payload;
        },
        setPendingImpactConfig(state, payload) {
            state.pendingImpactConfig = payload;
        }
    },
    actions: {
        async getImpactIndicators({commit, dispatch}) {
            commit("setLoadingImpact", true);
            API.get(
                'getImpactCustomizationData',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            )
                .then((reply) => {
                    commit('setImpactIndicators', reply);
                    commit("setLoadingImpact", false);
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit("setLoadingImpact", false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
        },
        async getImpactConfiguration({commit, dispatch}) {
            commit("setLoadingImpact", true);
            API.get(
                'getImpactConfiguration',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {}
                }
            )
                .then((reply) => {
                    if (!reply || reply.settings.length == 0) {
                        commit('setImpactConfiguration', []);
                        commit('setPendingImpactConfig', true);
                    }
                    else {
                        commit('setImpactConfiguration', reply.settings);
                        commit('setPendingImpactConfig', false);
                    }
                    commit("setLoadingImpact", false);
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit("setLoadingImpact", false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;
                    }
                });
        },
        async saveDefaultImpactIndicators({commit, dispatch}, data) {
            commit('setLoadingImpactConfig', true);
            API.post(
                'saveDefaultImpactIndicators',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {settings: data}
                }
            )
                .then((reply) => {
                    if (!reply || reply.settings.length == 0) {
                        commit('setImpactConfiguration', []);
                        commit('setPendingImpactConfig', true);
                    }
                    else {
                        commit('setImpactConfiguration', reply.settings);
                        commit('setPendingImpactConfig', false);
                    }
                    commit("setLoadingImpactConfig", false);
                    toast.success(i18n.global.t('alert.success.defaultIndicatorsSaved'));
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit("setLoadingImpactConfig", false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;    
                    }
                });
        },
        async updateDefaultImpactIndicators({commit, dispatch}, data) {
            commit('setLoadingImpactConfig', true);
            API.patch(
                'updateDefaultImpactIndicators',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {settings: data}
                }
            )
                .then(() => {
                    commit('setImpactConfiguration', data);
                    commit("setLoadingImpactConfig", false);
                    toast.success(i18n.global.t('alert.success.defaultIndicatorsSaved'));
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit("setLoadingImpactConfig", false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;    
                    }
                });
        },
    },
    getters: {
        getLoadingImpact: state => {
            return state.loadingImpact;
        },
        getImpactIndicators: state => {
            return state.impactIndicators;
        },
        getImpactConfiguration: state => {
            return state.impactConfiguration;
        },
        getSavingImpactIndicators: state => {
            return state.savingImpactIndicators;
        },
        getLoadingImpactConfig: state => {
            return state.loadingImpactConfig;
        },
        getMappedDefaultIndicators: state => {
            let indicators = [];
            if (state.impactConfiguration.length > 0) {
                state.impactConfiguration.forEach(category => {
                    category.indicators.forEach(indicator => {
                        indicators.push(indicator.code);
                    })
                });
            }
            return indicators;
        },
        getPendingImpactConfig: state => {
            return state.pendingImpactConfig;
        }
    }
}

export default customization;
