<template>
    <div v-click-outside="onClickOutside" id="dropdown-menu">
        <div @click="toggleMenu()" v-if="isIconMenu" class="d-flex flex-row justify-content-center align-items-center trigger">
            <slot name="trigger"></slot>
        </div>
        <div @click="toggleMenu()" v-else class="d-flex flex-row justify-content-center align-items-center trigger">
            <a class="btn" :class="[isChildActive(navItem.children) ? fontType + '-bold underline' : fontType + '-regular']">{{$t(navItem.name)}}</a> 
            <div :class="[showMenu ? 'invert' : '']" class="mx-1"><i  class="icon fas fa-chevron-down"></i></div>
        </div>
        <div :style="{ display: showMenu ? 'flex' : 'none !important' }" :class="[mode === 'collapse' ? 'collapsable-menu mt-2' : 'menu']" class="mt-1 flex-column w-100">
            <div v-for="(item, index) in navItem.children" :key="index" class="px-3 text-capitalize py-2" :class="{'blue-gradient': item.active && !alterColors, 'yellow-gradient': item.active && alterColors}">
                <template v-if="item.children">
                    <DropdownMenu class="sub-menu" :fontType="fontType" :mode="mode" :userCapability="userCapability" :isIconMenu="true" @actionEmitted="emitAction($event)" :navItem="item">
                        <template #trigger>
                            <div class="flex flex-row align-item-center">
                                <a :class="fontType + '-regular btn'">{{$t(item.name)}}</a> <i class="icon fas fa-chevron-right"></i>
                            </div>
                        </template>
                    </DropdownMenu>
                </template>
                <custom-link v-else-if="mode === 'navigation'" 
                    :class="{'disabled-by-capability': userCapability < (item.minimumCapability ? item.minimumCapability : 0) || userCapability === item.excludedCapability}" 
                    :to="userCapability < (item.minimumCapability ? item.minimumCapability : 0) || userCapability === item.excludedCapability ? '' : item.url" 
                    :active-class="userCapability < (item.minimumCapability ? item.minimumCapability : 0) ? '' : fontType + '-bold underline'" 
                    @click="checkCapabilities(userCapability < (item.minimumCapability ? item.minimumCapability : 0) || userCapability === item.excludedCapability)" 
                    :inactive-class="fontType + '-regular'">
                    <span>{{$t(item.name)}}</span>
                </custom-link>
                <div v-else class="d-flex flex-row justify-content-center align-items-center">
                    <custom-link v-if="item.url && (userCapability >= item.requiredCapability)" @click="toggleMenu()" :to="item.url">
                        <span class="link">{{$t(item.name)}}</span>
                    </custom-link>
                    <a v-else-if="item.action && mode == 'table-header-button'" :class="fontType + '-regular action' + (item.active ? ' font-white' : '')"  @click="emitAction(item.action)">{{ $t('content.impactIndicators.metrics.' + item.name) }}</a>
                    <a v-else-if="item.action && mode == 'header-dropdown'" :class="fontType + '-regular action' + (item.active ? ' font-white' : '')"  @click="emitAction(item.action)">{{ $t(item.name)}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
export default {
    props: {
        navItem: Object,
        mode: {type: String, default: 'navigation'},
        userCapability: [Number, String],
        isIconMenu: {type: Boolean, default: false},
        fontType: {type: String, default: 'poppins'},
        alterColors: {type: Boolean, default: false}
    },
    data() {
        return {
            showMenu: false
        }
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        checkCapabilities(check) {
            this.toggleMenu();
            this.$store.dispatch('setCheckingCapabilityStatus', check);
        },
        isChildActive(children) {
            let active = children.find(child => this.currentRoutePath.includes(child.url))
            if (active) {
                return true;
            }
            else {
                return false;
            }
        },
        onClickOutside() {
            this.showMenu = false;
        },
        emitAction(action) {
            this.toggleMenu();
            this.$emit('actionEmitted', action);
        }
    },
    computed: {
        currentRoutePath() {
            return this.$route.fullPath;
        },
    },
    directives: {
      clickOutside: vClickOutside.directive
    }
}
</script>

<style lang="scss" scoped>

#dropdown-menu {
    position: relative;
    .icon {
        color: #22304c;
        width: 24px;
        height: 24px;
        &:hover {
            color: #596378;
            transition: all 150ms ease-in-out;
        }
    }
    .menu {
        min-width: 160px;
        position: absolute;
        z-index: 9999;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0px 10px 25px rgba(34, 48, 76,  0.07);
    }
    .collapsable-menu {
        background: #fff;
    }
    .trigger {
        color: #22304c;
        cursor: pointer;
        .invert {
            transform: rotate(180deg);
        }
        .btn {
            border: none;
            padding: 0 !important;
        }
        a {
            font-size: 0.875rem;
        }
    }
    &.sub-menu {
        .menu {
            left: 75% !important;
            top: 1rem !important;
        }
        .collapsable-menu {
            background: #fff;
        }
        .icon {
            color: #22304c;
            width: 14px;
            height: 14px;
        }
    }
}

</style>