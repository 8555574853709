import { getSessionToken } from "../http/auth";
import { API } from "../http/api";
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();
function stringCutter(str, maxLen = 25, separator = " ") {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
}

const dataHub = {
    namespaced: true,
    state: {
        companyScreening: {
            regions: [],
            industries: [],
            sectors: [],
            exclusions: [],
            controversies: []
        },
        companyScreeningResults: {
            companies: [],
            columns: [],
            page: 1,
            perPage: 50,
            total: 1,
            order: "asc",
            orderBy: null,
        },
        disableCompanyScreeningRunButton: false,
        loadingCompanyScreeningData: false,
        fundScreeningResults: {
            companies: [],
            columns: [],
            page: 1,
            perPage: 50,
            total: 1,
            order: "asc",
            orderBy: null,
        },
        disableFundScreeningRunButton: false,
        loadingFundScreeningData: false,
        countryScreening: {
            regions: [],
            exclusions: []
        },
        countryScreeningResults: [],
        disableCountryScreeningRunButton: false,
        loadingCountryScreeningData: false,
        runningTest: false,
        testReady: false,
        matchingFunds: ['URTH', 'IAGG'],
        loadingMatchingCheckedFunds: false,
        matchingCheckedFunds: [],
        savingPortfolio: false,
        lookupPortfolio: {},
        lookupPortfolioSaved: false,
        PAICalculated: false,
        testProgress: 0,
        timeout: false,
        screeningReady: false,
        templates:[],
        fundScreening: {},
        selectedTemplate: null,
        templateDeleted: false,
        deletingTemplate: false,
        exportingCSV: false,
        csvUrl: undefined,
        loadingScreening: false,
        loadingTemplateCSV: false,
        estimatedData: false,
        lastPortfolio: {
            investmentAmount: null,
            investmentDay: '',
            aum: false,
            portfolio: {
                items: [],
                cash: ''
            },
            benchmark: {
                items: [],
                cash: ''
            }
        }
    },
    mutations: {
        setCompanyScreeningData(state, payload) {
            state.companyScreening = payload;
        },
        setCompanyScreeningResults(state, payload) {
            state.companyScreeningResults = payload;
        },
        setDisableCompanyScreeningRunButton(state, payload) {
            state.disableCompanyScreeningRunButton = payload;
        },
        setLoadingCompanyScreeningData(state, payload) {
            state.loadingCompanyScreeningData = payload;
        },
        setFundScreeningResults(state, payload) {
            state.fundScreeningResults = payload;
        },
        setDisableFundScreeningRunButton(state, payload) {
            state.disableFundScreeningRunButton = payload;
        },
        setCountryScreeningData(state, payload) {
            state.countryScreening = payload;
        },
        setFundScreeningData(state, payload) {
            state.fundScreening = payload;
        },
        setCountryScreeningResults(state, payload) {
            state.countryScreeningResults = payload;
        },
        setDisableCountryScreeningRunButton(state, payload) {
            state.disableCountryScreeningRunButton = payload;
        },
        setLoadingCountryScreeningData(state, payload) {
            state.loadingCountryScreeningData = payload;
        },
        setLoadingFundScreeningData(state, payload) {
            state.loadingFundScreeningData = payload;
        },
        setRunningTest(state, payload) {
            state.runningTest = payload
        },
        setTestReady(state, payload) {
            state.testReady = payload;
        },
        setMatchingCheckedFunds(state, payload) {
            state.matchingCheckedFunds = payload;
        },
        setLoadingMatchingCheckedFunds(state, payload) {
            state.loadingMatchingCheckedFunds = payload;
        },
        setSavingPortfolio(state, payload) {
            state.savingPortfolio = payload;
        },
        setLookupPortfolio(state, payload) {
            state.lookupPortfolio = payload;
        },
        setlookupPortfolioSaved(state, payload) {
            state.lookupPortfolioSaved = payload;
        },
        setPAICalculated(state, payload) {
            state.PAICalculated = payload
        },
        setTestProgress(state, payload) {
            state.testProgress = payload;
        },
        setTimeout(state, payload) {
            state.timeout = payload;
        },
        setScreeningReady(state, payload) {
            state.screeningReady = payload;
        },
        setTemplates(state, payload) {
            state.templates = payload;
        },
        addTemplate(state, payload) {
            let templates = JSON.parse(JSON.stringify(state.templates));
            templates.unshift(payload);
            state.templates = templates;
        },
        updateTemplate(state, payload) {
            let idx = state.templates.findIndex(template => template.id === payload.id);
            if (idx > -1) {
                state.templates[idx] = payload;
            }
        },
        deleteTemplate(state, payload) {
            let templates = JSON.parse(JSON.stringify(state.templates));
            let templateIdx = templates.findIndex(template => template.id === payload);
            if (templateIdx > -1) {
                templates.splice(templateIdx, 1);
            }
            state.templates = templates;
        },
        setSelectedTemplate(state, payload) {
            state.selectedTemplate = payload;
        },
        setTemplateDeleted(state, payload) {
            state.templateDeleted = payload;
        },
        setDeletingTemplate(state, payload) {
            state.deletingTemplate = payload;
        },
        setExportingCSV(state, payload) {
            state.exportingCSV = payload;
        },
        setCSVUrl(state, payload) {
            state.csvUrl = payload;
        },
        setLoadingScreening(state, payload) {
            state.loadingScreening = payload;
        },
        setSelectedTemplateCSV(state, payload) {
            state.selectedTemplate['securities'] = payload;
        },
        setLoadingTemplateCSV(state, payload) {
            state.loadingTemplateCSV = payload;
        },
        setEstimatedData(state, payload) {
            state.estimatedData = payload;
        },
        setLastPortfolio(state, payload) {
            state.lastPortfolio = payload
        }
    },
    actions: {
        async getCompanyScreeningData({commit, dispatch}) {
            commit("setLoadingCompanyScreeningData", true);
            API.get("screeningByCompany", "", {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {},
            })
            .then((reply) => {
                let screening = {};
                screening.regions = reply.regions;
                screening.industries = reply.industries;
                screening.sectors = reply.sectors;
                screening.exclusions = reply.exclusionTypes.map((e) => {
                    return {
                    label: e,
                    param: "involvement_" + e.toLowerCase().replace(" ", "_"),
                    options: [
                        {
                        label: "None",
                        value: "0% - 5%",
                        },
                        {
                        label: "Minor",
                        value: "5.1% - 10%",
                        },
                        {
                        label: "Serious",
                        value: "10.1% - 20%",
                        },
                    ],
                    };
                });
                screening.controversies = [
                    {
                    label: null,
                    param: "controversy_type",
                    options: [
                        {
                        label: "None",
                        value: "None",
                        },
                    ],
                    },
                ];
                screening.controversies[0].options = screening.controversies[0].options.concat(
                    reply.controversies.map((e) => {
                    return {
                        label: e,
                        value: e,
                    };
                    })
                );
                commit("setLoadingCompanyScreeningData", false);
                commit("setCompanyScreeningData", screening);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async getCompanyScreeningResults({ commit, dispatch }, data) {
            if (data.newScreening)
                commit('setScreeningReady', false);
            commit('setLoadingScreening', true);
            let companyScreeningParams = this.getters["dataHub/getCompanyScreeningResults"];
            commit("setDisableCompanyScreeningRunButton", true);
            API.post("screeningByCompany", `?page=${data.params.page}&per_page=${data.params.perPage ? data.params.perPage : companyScreeningParams.perPage}&find=${data.params.find}&order_by=${data.params.orderBy}&order=${data.params.order}`, 
            {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: data.query,
            })
            .then((reply) => {
                if (reply.page > 1) 
                companyScreeningParams.companies = [...companyScreeningParams.companies, ...reply.companies];
                else {
                    companyScreeningParams.companies = reply.companies;
                    companyScreeningParams.columns = reply.columns;
                }
                companyScreeningParams.find = reply.find;
                companyScreeningParams.order = reply.order? reply.order : companyScreeningParams.order;
                companyScreeningParams.orderBy = reply.orderBy?  reply.orderBy.replaceAll('_', ' ') : companyScreeningParams.orderBy.replaceAll('_', ' ');
                companyScreeningParams.page = reply.page;
                companyScreeningParams.perPage = reply.perPage;
                companyScreeningParams.total = reply.total;
                if (reply.companies.length == 0) {
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));
                }
                else {
                    commit("setCompanyScreeningResults", companyScreeningParams);
                }
                if (data.newScreening)
                    commit('setScreeningReady', true);
                commit('setLoadingScreening', false);
                commit("setDisableCompanyScreeningRunButton", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    if (data.newScreening)
                        commit('setScreeningReady', false);
                    commit("setDisableCompanyScreeningRunButton", false);
                    commit('setLoadingScreening', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;
                }
            });
        },
        async getFundScreeningResults({ commit, dispatch }, data) {
            if (data.newScreening)
                commit('setScreeningReady', false);
            commit('setLoadingScreening', true);
            let fundScreeningParams = this.getters["dataHub/getFundScreeningResults"];
            commit("setDisableFundScreeningRunButton", true);
            API.post("runFundScreening", `?page=${data.params.page}&per_page=${data.params.perPage ? data.params.perPage : fundScreeningParams.perPage}&find=${data.params.find}&order_by=${data.params.orderBy}&order=${data.params.order}`,            
            {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: data.query,
            })
            .then((reply) => {
                if (reply.page > 1) 
                    fundScreeningParams.companies = [...fundScreeningParams.companies, ...reply.companies];
                else {
                    fundScreeningParams.companies = reply.companies;
                    fundScreeningParams.columns = reply.columns;
                }
                fundScreeningParams.find = reply.find;
                fundScreeningParams.order = reply.order? reply.order : fundScreeningParams.order;
                fundScreeningParams.orderBy = reply.orderBy?  reply.orderBy.replaceAll('_', ' ') : fundScreeningParams.orderBy.replaceAll('_', ' ');
                fundScreeningParams.page = reply.page;
                fundScreeningParams.perPage = reply.perPage;
                fundScreeningParams.total = reply.total;
                if (reply.companies.length == 0) {
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));
                }
                else {
                    commit("setFundScreeningResults", fundScreeningParams);
                }
                if (data.newScreening)
                    commit('setScreeningReady', true);
                commit('setLoadingScreening', false);
                commit("setDisableFundScreeningRunButton", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    if (data.newScreening)
                        commit('setScreeningReady', false);
                    commit("setDisableFundScreeningRunButton", false);
                    commit('setLoadingScreening', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;
                }
            });
        },
        async getCountryScreeningData({ commit, dispatch }) {
            commit("setLoadingCountryScreeningData", true);
            API.get("screeningByCountry", "", {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {},
            })
            .then((reply) => {
                let screening = {};
                screening.regions = reply.regions;
                screening.exclusions = reply.exclusionTypes;
                commit("setLoadingCountryScreeningData", false);
                commit("setCountryScreeningData", screening);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async getFundScreeningData({ commit, dispatch }) {
            commit("setLoadingFundScreeningData", true);
            API.get("getFundScreeningFilters", "", {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {},
            })
            .then((reply) => {
                commit("setLoadingFundScreeningData", false);
                commit("setFundScreeningData", reply);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async getCountryScreeningResults({ commit, dispatch }, data) {
            commit('setScreeningReady', false);
            commit("setDisableCountryScreeningRunButton", true);
            API.post("screeningByCountry", "", {
                headers: {
                Authorization: await getSessionToken(),
                },
                body: data,
            })
            .then((reply) => {
                if (reply.length) {
                    let results = {};
                    results.data = reply;
                    results.columns = Object.keys(reply?.[0])?.map((e) => {
                    return {
                        key: e,
                        value: stringCutter(
                        e.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                            return str.toUpperCase();
                        })
                        ),
                    };
                    });
                    commit("setCountryScreeningResults", results);
                    commit('setScreeningReady', true);
                } else {
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));
                }
                commit("setDisableCountryScreeningRunButton", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setDisableCountryScreeningRunButton", false);
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));
                    throw err;    
                }
            });
        },
        catchError({commit, dispatch}, err) {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                commit('setRunningTest', false);
                if (err.message && err.message.includes('504')) 
                    commit('setTimeout', true);
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;    
            }
        },
        async runTest({commit, dispatch}, data) {
            commit('setLookupPortfolio', {})
            commit('setlookupPortfolioSaved', false)
            commit('setRunningTest', true);
            dispatch('pai/resetPAIProgress', 0, {root: true});
            commit('setTestReady', false);
            commit('setPAICalculated', false);
            commit('setTimeout', false);
            API.post(
                'portfolioV2',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                        ...data
                    }
                }
            ).then( async () => {
                commit('setTestProgress', 10);
                commit('setLookupPortfolio', data);
                API.get(
                    'providerRetrieveV2',
                    '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }).then(async () => {
                    commit('setTestProgress', 30);
                    API.post(
                        'recalculatePortfolioV2',
                        '',
                    {
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }).then(async () => {
                        commit('setTestProgress', 50);
                        API.post(
                            'getEuTaxonomy',
                            '',
                            {
                                headers: {
                                    Authorization: await getSessionToken()
                                }
                            }
                        ).then( async () => {
                            commit('setTestProgress', 90);
                            API.post(
                                'calculateImpactV2',
                                '?type=0',
                                {
                                    headers: {
                                        Authorization: await getSessionToken()
                                    },
                                    body: {
                                        indicators: this.getters['customization/getMappedDefaultIndicators']
                                    }
                                }
                            ).then(async () => {
                                API.post(
                                    'calculateImpactV2',
                                    '?type=2',
                                    {
                                        headers: {
                                            Authorization: await getSessionToken()
                                        },
                                        body: {
                                            indicators: this.getters['customization/getMappedDefaultIndicators']
                                        }
                                    }
                                ).then(() => {
                                    commit('setTestProgress', 100);
                                    commit('setTestReady', true);
                                    commit('setRunningTest', false);
                                    sessionStorage.setItem('lookupPortfolio', true)
                                    commit('setTestProgress', 0);
                                }).catch((err) => {
                                    dispatch('catchError', err);
                                })
                            }).catch((err) => {
                                dispatch('catchError', err);
                            })
                        }).catch((err) => {
                            dispatch('catchError', err);
                        })
                    }).catch((err) => {
                        dispatch('catchError', err);
                    })
                }).catch((err) => {
                    dispatch('catchError', err);
                })
            }).catch(err => {
                console.log(err);
                dispatch('catchError', err);
            })
        },
        async saveFromLookup({commit, dispatch}, data) {
            commit('setSavingPortfolio', true);
            API.post(
                'clientV2',
                'from-lookup',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                        ...data
                    }
                }
            ).then((reply) => {
                commit('setSavingPortfolio', false);
                let newClient = {
                    id: reply.id,
                    firstname: reply.firstname ? reply.firstname : '',
                    lastname: reply.lastname ? reply.lastname : '',
                    email: reply.email ? reply.email : '',
                    phone: reply.phone ? reply.phone : '',
                    imageUrl: reply.imageUrl === null ? undefined : `${reply.imageUrl}`,
                    portfolio: {
                        investmentDay: reply.portfolio.investmentDay ? reply.portfolio.investmentDay : '',
                        investmentAmount: reply.portfolio.investmentAmount ? reply.portfolio.investmentAmount : null,
                        updatedAt: reply.updatedAt ? reply.updatedAt : null,
                        portfolio: {
                            items: reply.portfolio.portfolio ? reply.portfolio.portfolio.items.map(item => ({
                                input: item.input,
                                id: item.id,
                                fundId: item.fundId,
                                holdingId: item.holdingId,
                                weight: item.weight,
                                valid: true,
                                checked: true,
                            })) : [],
                            cash: reply.portfolio.portfolio.cash ? reply.portfolio.portfolio.cash : 0,
                        },
                        benchmark: {
                            items: reply.portfolio.benchmark ? reply.portfolio.benchmark.items.map(item => ({
                                input: item.input,
                                id: item.id,
                                fundId: item.fundId,
                                holdingId: item.holdingId,
                                weight: item.weight,
                                valid: true,
                                checked: true,
                            })) : [],
                            cash: reply.portfolio.benchmark ? reply.portfolio.benchmark.cash ? reply.portfolio.benchmark.cash : 0 : 0,
                        }
                    }
                };
                commit('portfolioHub/addClient', newClient, {root: true});
                // Take out the comment if you're going to test the navigation directly to the client
                //dispatch('portfolioHub/selectClient', newClient, {root: true});
                commit('setlookupPortfolioSaved', true);
                toast.success(i18n.global.t('alert.success.cliendCreated'));
                // The lines below make the platform throw a error on navigation, couldn't solve it 
                // setTimeout(() => {
                //     router.push({
                //         path: 'Client',
                //         params: {id: newClient.id}
                //     })
                // }, 250);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    console.log(err);
                    commit('setSavingPortfolio', false);
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));    
                }
            })
        },
        async checkFunds({commit, state, dispatch}) {
            commit("setLoadingMatchingCheckedFunds", true);
            API.post("securitiesExistV2", "", {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {ids: state.matchingFunds},
            })
            .then((reply) => {
                let funds = reply.map(e => { return {fundId: e.securities?.[0]?.fundId, input: e.input} });
                commit("setLoadingMatchingCheckedFunds", false);
                commit("setMatchingCheckedFunds", funds);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));
                    throw err;    
                }
            });
        },
        setTimeout({commit}, data) {
            commit('setTimeout', data);
        },
        async getScreeningTemplates({commit, dispatch}, data) {
            API.get("getScreeningTemplates", data, {
                headers: {
                Authorization: await getSessionToken(),
                },
                body: {},
            })
            .then((reply) => {
                reply.forEach(template => {
                    template.editing = false;
                });
                commit('setTemplates', reply);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));
                    throw err;    
                }
            });
        },
        async addScreeningTemplate({commit, dispatch}, data) {
            API.post("saveScreeningTemplate", "", {
                headers: {
                Authorization: await getSessionToken(),
                },
                body: data,
            })
            .then((reply) => {
                reply.editing = false;
                commit('addTemplate', reply);
                commit('setSelectedTemplate', reply);
                toast.success(i18n.global.t('alert.success.templateCreated'));
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.noResultsScreening'));
                    throw err;    
                }
            });
        },
        async updateScreeningTemplate({commit, dispatch}, data) {
            API.patch("updateScreeningTemplate", data.id, {
                headers: {
                Authorization: await getSessionToken(),
                },
                body: data,
            })
            .then((reply) => {
                if (reply && reply.raw && reply.raw.length) {
                    reply.raw[0].editing = false;
                    commit('updateTemplate', reply.raw[0]);
                    setTimeout(() => {
                        commit('setSelectedTemplate', reply.raw[0]);
                    }); 
                }
                toast.success(i18n.global.t('alert.success.templateUpdated'));
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async deleteScreeningTemplate({commit, dispatch}, data) {
            commit('setTemplateDeleted', false);
            commit('setDeletingTemplate', true);
            API.del("deleteScreeningTemplate", data, {
                headers: {
                Authorization: await getSessionToken(),
                },
                body: {},
            })
            .then(() => {
                commit('deleteTemplate', data);
                commit('setTemplateDeleted', true);
                commit('setDeletingTemplate', false);
                toast.success(i18n.global.t('alert.success.templateDeleted'));
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setTemplateDeleted', false);
                    commit('setDeletingTemplate', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        selectTemplate({commit}, data) {
            commit('setSelectedTemplate', data );
        },
        async exportFundScreeningCSV({commit, dispatch}, data) {
            commit("setExportingCSV", true);
            API.post("getFundScreeningCSVv2", "", {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: data,
            })
            .then((reply) => {
                commit('setCSVUrl', reply.urlDownload);
                commit("setExportingCSV", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setExportingCSV", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async exportCompanyScreeningCSV({commit, dispatch}, data) {
            commit("setExportingCSV", true);
            API.post("getCompanyScreeningCSVv2", "", {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: data,
            })
            .then((reply) => {
                commit('setCSVUrl', reply.urlDownload);
                commit("setExportingCSV", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setExportingCSV", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async exportCountryScreeningCSV({commit, dispatch}, data) {
            commit("setExportingCSV", true);
            API.post("getCountryScreeningCSVv2", "", {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: data,
            })
            .then((reply) => {
                commit('setCSVUrl', reply.urlDownload);
                commit("setExportingCSV", false);
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setExportingCSV", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        setScreeningReady({commit}, data) {
            commit('setScreeningReady', data);
        },
        setLookupPortfolio({commit}, data) {
            commit('setLookupPortfolio', data);
        },
        resetFundScreeningResults({commit}) {
            commit('setFundScreeningResults', {
                companies: [],
                columns: [],
                page: 1,
                perPage: 20,
                total: 1
            });
            commit('setScreeningReady', false);
        },
        resetCompanyScreeningResuts({commit}) {
            commit('setCompanyScreeningResults', []);
            commit('setScreeningReady', false);
        },
        resetCountryScreeningResults({commit}) {
            commit('setCountryScreeningResults', [])
            commit('setScreeningReady', false);
        },
        async uploadTemplateCSV({commit, dispatch}, data) {
            let requestData = new FormData();
            requestData.append('csv', data.csv);
            API.put("uploadCSVTemplate", data.templateId, {
                headers: {
                    Authorization: await getSessionToken(),
                },
                'Content-Type':'multipart/form-data',
                body: requestData,
            })
            .then((reply) => {
                if (reply.type) {
                    switch (reply.type) {
                        case 1:
                            toast.error(i18n.global.t('alert.error.csvDoesNotContainFunds'));
                            break;
                        case 2:
                            toast.error(i18n.global.t('alert.error.csvDoesNotContainCompanies'));
                            break;
                        case 3:
                            toast.warning(i18n.global.t('alert.success.csvMixedCompany'));
                            break;
                        case 4:
                            toast.warning(i18n.global.t('alert.success.csvMixedFunds'));
                            break;                    
                        default:
                            break;
                    }
                }
                else if (reply === true) {
                    toast.success(i18n.global.t('alert.success.csvTemplateUploaded'));
                }
                
                commit('setLoadingTemplateCSV', false);
                
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setLoadingTemplateCSV', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        setLoadingTemplateCSV({commit}, data) {
            commit('setLoadingTemplateCSV', data);
        },
        async addScreeningCSVTemplate({commit, dispatch}, data) {
            API.post("saveScreeningTemplate", "", {
                headers: {
                Authorization: await getSessionToken(),
                },
                body: data.templateToCreate,
            })
            .then(async (reply) => {
                reply.editing = false;
                let requestData = new FormData();
                requestData.append('csv', data.csv);
                API.put("uploadCSVTemplate", reply.id, {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    'Content-Type':'multipart/form-data',
                    body: requestData,
                })
                .then(async(res) => {
                    if (res.type) {
                        switch (res.type) {
                            case 1:
                            case 2:
                                API.del("deleteScreeningTemplate", reply.id, {
                                    headers: {
                                    Authorization: await getSessionToken(),
                                    },
                                    body: {},
                                })
                                .then(() => {
                                    commit('deleteTemplate', reply.id);
                                    if (res.type === 1) {
                                        toast.error(i18n.global.t('alert.error.csvDoesNotContainFunds'));
                                    }
                                    else {
                                        toast.error(i18n.global.t('alert.error.csvDoesNotContainCompanies'));
                                    }
                                })
                                .catch((err) => {
                                    if (err.message && err.message.includes('401')) {
                                        dispatch('handleExpiredSession', {}, {root: true});
                                    }
                                    else {
                                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                                        throw err;    
                                    }
                                });
                                break;
                            case 3:
                                commit('addTemplate', reply);
                                commit('setSelectedTemplate', reply);  
                                toast.warning(i18n.global.t('alert.success.templateCreatedCSVMixedCompany'));
                                break;
                            case 4:
                                commit('addTemplate', reply);
                                commit('setSelectedTemplate', reply);   
                                toast.warning(i18n.global.t('alert.success.templateCreatedCSVMixedFunds'));
                                break;                    
                            default:
                                break;
                        }
                    }
                    else if (res === true) {
                        commit('addTemplate', reply);
                        commit('setSelectedTemplate', reply);
                        toast.success(i18n.global.t('alert.success.csvTemplateAdded'));  
                    }
                    commit('setLoadingTemplateCSV', false);
                })
                .catch((err) => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        commit('setLoadingTemplateCSV', false);
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        throw err;    
                    }
                });
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        setPAICalculated({commit}, data) {
            commit('setPAICalculated', data);
        },
        setEstimatedData({commit}, data) {
            commit('setEstimatedData', data);
        },
        async getLastPortfolio({commit}) {
            API.get(
                'getLastPortfolio',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    }
                }
            ).then(reply => {
                const lastPortfolio = {
                    aum: reply.aum,
                    benchmark: {
                        items: reply.distribution.filter(security => security.isBenchmark && security.input !== 'CASH'),
                        cash: reply.distribution.filter(security => security.isBenchmark && security.input == 'CASH').length > 0 ? reply.distribution.filter(security => security.isBenchmark && security.input == 'CASH')[0].weight : ''
                    },
                    investmentAmount: reply.investmentAmount,
                    investmentDay: reply.investmentDay,
                    portfolio: {
                        items: reply.distribution.filter(security => !security.isBenchmark && security.input !== 'CASH'),
                        cash: reply.distribution.filter(security => !security.isBenchmark && security.input == 'CASH').length > 0 ? reply.distribution.filter(security => !security.isBenchmark && security.input == 'CASH')[0].weight : ''
                    } 
                }
                commit('setLastPortfolio', lastPortfolio)
            }).catch(err => {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;
            })
        }
    },
    getters: {
        getCompanyScreening: (state) => {
            return state.companyScreening;
        },
        getCompanyScreeningResults: (state) => {
            return state.companyScreeningResults;
        },
        getDisableCompanyScreeningRunButton: (state) => {
            return state.disableCompanyScreeningRunButton;
        },
        getLoadingCompanyScreeningData: (state) => {
            return state.loadingCompanyScreeningData;
        },
        getFundScreeningResults: (state) => {
            return state.fundScreeningResults;
        },
        getDisableFundScreeningRunButton: (state) => {
            return state.disableFundScreeningRunButton;
        },
        getCountryScreening: (state) => {
            return state.countryScreening;
        },
        getCountryScreeningResults: (state) => {
            return state.countryScreeningResults;
        },
        getDisableCountryScreeningRunButton: (state) => {
            return state.disableCountryScreeningRunButton;
        },
        getLoadingCountryScreeningData: (state) => {
            return state.loadingCountryScreeningData;
        },
        getRunningTest: (state) => {
            return state.runningTest;
        },
        getReadyTest: (state) => {
            return state.testReady;
        },
        getMatchingCheckedFunds: (state) => {
            return state.matchingCheckedFunds;
        },
        getLoadingMatchingCheckedFunds: (state) => {
            return state.loadingMatchingCheckedFunds;
        },
        getSavingPortfolio: (state) => {
            return state.savingPortfolio;
        },
        getLookupPortfolio: (state) => {
            return state.lookupPortfolio;
        },
        getLookupPortfolioSaved: (state) => {
            return state.lookupPortfolioSaved;
        },
        getPAICalculated: state => {
            return state.PAICalculated;
        },
        getTestProgress: state => {
            return state.testProgress;
        },
        getTimeout: state => {
            return state.timeout;
        },
        getScreeningReady: state => {
            return state.screeningReady;
        },
        getLoadingScreening: state => {
            return state.loadingScreening;
        },
        getTemplates: state => {
            return state.templates;
        },
        getFundScreening: (state) => {
            return state.fundScreening;
        },
        getSelectedTemplate: state => {
            return state.selectedTemplate;
        },
        getTemplateDeleted: state => {
            return state.templateDeleted;
        },
        deletingTemplate: state => {
            return state.deletingTemplate;
        },
        getCSVUrl: state => {
            return state.csvUrl;
        },
        getPortfolioDayOfFirstInvestment: state => {
            return state.lookupPortfolio?.investmentDay
        },
        getLookupPortfolioHasBenchmark: state => {
            if (state.lookupPortfolio?.benchmark && state.lookupPortfolio?.benchmark?.items?.length > 0)
                return true;
            else
                return false; 
        },
        getLoadingTemplateCSV: state => {
            return  state.loadingTemplateCSV;
        },
        getEstimatedData: state => {
            return state.estimatedData;
        },
        getLastPortfolio: state => {
            return state.lastPortfolio;
        }
    },
};

export default dataHub;
