import {getSessionToken} from '../http/auth'
import {API} from '../http/api'
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();

function returnElement(array, name) {
  if (array.length > 1) {
    let totalIdx = array.findIndex(entry => entry.name === name);
    let total = array.find(entry => entry.name === name);
    if (total && totalIdx) {
      array.splice(totalIdx, 1);
      return total;
    }
  }
  return 0;
}

const common = {
    namespaced: true,
    state: {
      esg: {
        portfolio: {
            companies: {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: "value"
            },
            countries: {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: "value"
            },
        },
        benchmark: {
            companies: {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: "value"
            },
            countries: {
                e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: "value"
            },
        }
      },
      loadingESG: false,
      loadingESGResults: false,
      errorESG: false,
      controversy: {
        portfolio: {
            results: [],
            columns: [],
            page: 1,
            total: 1,
            perPage: 20,
            order: "asc",
            find: "",
            orderBy: ""
        },
        benchmark: {
            results: [],
            columns: [],
            page: 1,
            total: 1,
            perPage: 20,
            order: "asc",
            find: "",
            orderBy: ""
        }
      },
      loadingControversy: false,
      loadingControversyResults: false,
      products: {
        portfolio: {
            companies: [],
            categories: [],
            page: 1,
            total: 1,
            perPage: 20,
            order: "asc",
            find: "",
            orderBy: ""
        },
        benchmark: {
            companies: [],
            categories: [],
            page: 1,
            total: 1,
            perPage: 20,
            order: "asc",
            find: "",
            orderBy: ""
        }
      },
      loadingProducts: false,
      loadingProductsResults: false,
      esgCSVURL: null,
      loadingEsgCSVURL: false,
      esgCountriesCSVURL: null,
      loadingEsgCountriesCSVURL: false,
      productsCSVURL: null,
      loadingProductsCSVURL: false,
      controversyCSVURL: null,
      loadingControversyCSVURL: false,
      loadingCSVURL: false,
      euTaxonomyData: {
        portfolio: {
            results: [],
            page: 1,
            total: 1,
            perPage: 20,
            order: "asc",
            find: "",
            orderBy: ""
        },
        benchmark: {
            results: [],
            page: 1,
            total: 1,
            perPage: 20,
            order: "asc",
            find: "",
            orderBy: ""
        }
      },
      euTaxonomySummary: {
        portfolio: {},
        benchmark: {}
      },
      loadingTaxonomyData: false,
      loadingTaxonomySummaryData: false,
      loadingTaxonomyResult: false,
      errorTaxonomy: false,
      errorTaxonomySummary: false,

      esgPdf: {
        e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
        s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
        g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
        find: "",
        order: "desc",
        orderBy: "weight",
        page: 1,
        perPage: 5,
        flag: "pdf",
        total: 1
      },
      esgLimited: {
        portfolio: {
            companies: {
                companies: [],
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 20,
                total: 1,
                portfolioTotal: null
            },
            countries: {
                companies: [],
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 20,
                total: 1,
                portfolioTotal: null
            },
        },
        benchmark: {
            companies: {
                companies: [],
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 20,
                total: 1,
                portfolioTotal: null
            },
            countries: {
                companies: [],
                find: "",
                order: "desc",
                orderBy: "weight",
                page: 1,
                perPage: 20,
                total: 1,
                portfolioTotal: null
            },
        }
      },
      exclusionsWatchlistPdf: {},
      redCompaniesByIndicator: [],
      redCompaniesByWeight: [],
      productsError: false,
      controversyError: false,
      csvURL: null,
      csvTab: null,
    },
    mutations: {
      setESG(state, payload) {
        state.esg = payload;
      },
      setESGPortfolio(state, payload) {
        state.esg.portfolio.companies = JSON.parse(JSON.stringify(payload));
      },
      setESGBenchmark(state, payload) {
        state.esg.benchmark.companies = JSON.parse(JSON.stringify(payload));
      },
      setESGPdfData(state, payload) {
        state.esgPdf = payload;
      },
      setLoadingESG(state, payload) {
        state.loadingESG = payload;
      },
      setErrorESG(state, payload) {
        state.errorESG = payload;
      },
      setESGCountriesPortfolio(state, payload) {
        state.esg.portfolio.countries = JSON.parse(JSON.stringify(payload));
      },
      setESGCountriesBenchmark(state, payload) {
        state.esg.benchmark.countries = JSON.parse(JSON.stringify(payload));
      },
      setErrorESGCountries(state, payload) {
        state.errorESGCountries = payload;
      },
      setLoadingESGResults(state, payload) {
        state.loadingESGResults = payload;
      },
      setControversyData(state, payload) {
        state.controversy = payload;
      },
      setControversyDataPortfolio(state, payload) {
        state.controversy.portfolio = payload;
      },
      setControversyDataBenchmark(state, payload) {
        state.controversy.benchmark = payload;
      },
      setLoadingControversy(state, payload) {
        state.loadingControversy = payload;
      },
      setLoadingControversyResults(state, payload) {
        state.loadingControversyResults = payload;
      },
      setProductsData(state, payload) {
        state.products = payload;
      }, 
      setPortfolioProductsData(state, payload) {
        state.products.portfolio = payload;
      },
      setBenchmarkProductsData(state, payload) {
        state.products.benchmark = payload;
      },
      setLoadingProducts(state, payload) {
        state.loadingProducts = payload;
      },
      setLoadingProductsResults(state, payload) {
        state.loadingProductsResults = payload;
      },
      setEsgCSVURL(state, payload) {
        state.esgCSVURL = payload;
      },
      setLoadingEsgCSVURL(state, payload) {
        state.loadingEsgCSVURL = payload;
      },
      setEsgCountriesCSVURL(state, payload) {
        state.esgCountriesCSVURL = payload;
      },
      setLoadingEsgCountriesCSVURL(state, payload) {
        state.loadingEsgCountriesCSVURL = payload;
      },
      setProductsCSVURL(state, payload) {
        state.productsCSVURL = payload;
      },
      setLoadingProductsCSVURL(state, payload) {
        state.loadingProductsCSVURL = payload;
      },
      setControversyCSVURL(state, payload) {
        state.controversyCSVURL = payload;
      },
      setLoadingControversyCSVURL(state, payload) {
        state.loadingControversyCSVURL = payload;
      },
      setCSVURL(state, payload) {
        state.csvURL = payload;
      },
      setLoadingCSVURL(state, payload) {
        state.loadingCSVURL = payload;
      },
      setPricingPlans(state, payload) {
        state.pricingPlans = payload;
      },
      setTaxonomyDataPortfolio(state, payload) {
        state.euTaxonomyData.portfolio = payload;
      },
      setTaxonomyDataBenchmark(state, payload) {
        state.euTaxonomyData.benchmark = payload;
      },
      setTaxonomySummaryData(state, payload) {
        state.euTaxonomySummary.portfolio = payload.portfolio;
        state.euTaxonomySummary.benchmark = payload.benchmark;
      },
      setLoadingTaxonomyData(state, payload) {
        state.loadingTaxonomyData = payload;
      },
      setLoadingTaxonomySummaryData(state, payload) {
        state.loadingTaxonomySummaryData = payload;
      },
      setLoadingTaxonomyResult(state, payload) {
        state.loadingTaxonomyResult = payload;
      },
      setErrorTaxonomy(state, payload) {
        state.errorTaxonomy = payload;
      },
      setErrorTaxonomySummary(state, payload) {
        state.errorTaxonomySummary = payload;
      },
      setPdfExclusionsWatchlist(state, payload) {
        state.exclusionsWatchlistPdf = payload;
      },
      setRedCompaniesByIndicator(state, payload) {
        state.redCompaniesByIndicator = payload;
      },
      setRedCompaniesByWeight(state, payload) {
        state.redCompaniesByWeight = payload;
      },
      setProductsError(state, payload) {
        state.productsError = payload;
      },
      setControversyError(state, payload){
        state.controversyError = payload;
      },
      setCurrentCSVTab(state, payload) {
        state.csvTab = payload;
      },
      setMinPAIYear(state, payload) {
        state.minPAIYear = payload;
      },
      setLimitedESGData(state, payload) {
        if(payload.data.page > 1) {
            state.esgLimited[payload.mode][payload.subMode].companies = [...state.esgLimited[payload.mode][payload.subMode].companies, ...payload.data.companies.companies];
        }
        else {
            state.esgLimited[payload.mode][payload.subMode].companies = payload.data.companies?.companies ? payload.data.companies?.companies : [];
            state.esgLimited[payload.mode][payload.subMode].portfolioTotal = returnElement(state.esgLimited[payload.mode][payload.subMode].companies, 'TOTAL');
        }
        state.esgLimited[payload.mode][payload.subMode].find = payload.data.find;
        state.esgLimited[payload.mode][payload.subMode].order = payload.data.order;
        state.esgLimited[payload.mode][payload.subMode].orderBy = payload.data.orderBy;
        state.esgLimited[payload.mode][payload.subMode].page = payload.data.page;
        state.esgLimited[payload.mode][payload.subMode].perPage = payload.data.perPage;
        state.esgLimited[payload.mode][payload.subMode].total = payload.data.total;
      },
    },
    actions: {
      async getInitialESG({commit, dispatch}, data) {
        commit('setErrorESG', false);
        let esgParameters = this.getters["common/getESGPortfolio"];
        let esgPdfParameters = this.getters["common/getESGPdfData"];
        let isLimited = this.getters['profile/getUserCapability'] == 7;
        commit('setLoadingESG', true);
        API.get(
            'getESGV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : esgParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${false}&limited=${isLimited}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then( async reply => {
            if (isLimited) {
                commit('setLimitedESGData', {data: reply, mode: 'portfolio', subMode: 'companies'});
            }
            else {
                if (data.flag && data.flag == 'pdf') {
                    if (reply.esg.page > 1) {
                        esgPdfParameters.e.companies = [...esgPdfParameters.e.companies, ...reply.esg.e.companies];
                        esgPdfParameters.s.companies = [...esgPdfParameters.s.companies, ...reply.esg.s.companies];
                        esgPdfParameters.g.companies = [...esgPdfParameters.g.companies, ...reply.esg.g.companies];
                    } else {
                        esgPdfParameters.e.benchmarkTotal = returnElement(reply.esg.e.companies, 'BENCHMARK');
                        esgPdfParameters.e.portfolioTotal = returnElement(reply.esg.e.companies, 'TOTAL');
                        esgPdfParameters.s.benchmarkTotal = returnElement(reply.esg.s.companies, 'BENCHMARK');
                        esgPdfParameters.s.portfolioTotal = returnElement(reply.esg.s.companies, 'TOTAL')
                        esgPdfParameters.g.benchmarkTotal = returnElement(reply.esg.g.companies, 'BENCHMARK');
                        esgPdfParameters.g.portfolioTotal = returnElement(reply.esg.g.companies, 'TOTAL');
                        esgPdfParameters.e.companies = reply.esg.e.companies;
                        esgPdfParameters.s.companies = reply.esg.s.companies;
                        esgPdfParameters.g.companies = reply.esg.g.companies;
                        esgPdfParameters.e.indicators = reply.esg.e.indicators;
                        esgPdfParameters.s.indicators = reply.esg.s.indicators;
                        esgPdfParameters.g.indicators = reply.esg.g.indicators;
                    }
                    esgPdfParameters.find = reply.esg.find;
                    esgPdfParameters.order = reply.esg.order;
                    esgPdfParameters.orderBy = reply.esg.orderBy;
                    esgPdfParameters.page = reply.esg.page;
                    esgPdfParameters.perPage = reply.esg.perPage;
                    esgPdfParameters.total = reply.esg.total;
                    commit('setESGPdfData', esgPdfParameters);
                } 
                else {
                    if (reply.esg.page > 1) {
                        esgParameters.e.companies = [...esgParameters.e.companies, ...reply.esg.e.companies];
                        esgParameters.s.companies = [...esgParameters.s.companies, ...reply.esg.s.companies];
                        esgParameters.g.companies = [...esgParameters.g.companies, ...reply.esg.g.companies];
                    } else {
                        esgParameters.e.portfolioTotal = returnElement(reply.esg.e.companies, 'TOTAL');
                        esgParameters.s.portfolioTotal = returnElement(reply.esg.s.companies, 'TOTAL')
                        esgParameters.g.portfolioTotal = returnElement(reply.esg.g.companies, 'TOTAL');
                        esgParameters.e.companies = reply.esg.e.companies;
                        esgParameters.s.companies = reply.esg.s.companies;
                        esgParameters.g.companies = reply.esg.g.companies;
                        esgParameters.e.indicators = reply.esg.e.indicators;
                        esgParameters.s.indicators = reply.esg.s.indicators;
                        esgParameters.g.indicators = reply.esg.g.indicators;
                    }
                    esgParameters.find = reply.esg.find;
                    esgParameters.order = reply.esg.order;
                    esgParameters.orderBy = reply.esg.orderBy;
                    esgParameters.page = reply.esg.page;
                    esgParameters.perPage = reply.esg.perPage;
                    esgParameters.total = reply.esg.total;
                    commit('setESGPortfolio', esgParameters);
                }
            }
            if(data.benchmark) {
                let esgParameters = this.getters["common/getESGBenchmark"];
                API.get(
                    'getESGV2'+(await getSessionToken() === null ? 'Shared' : ''),
                    `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : esgParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${true}&limited=${isLimited}`,
                    {
                      headers: {
                        Authorization: await getSessionToken()
                      }
                    }
                ).then(reply => {
                    if (isLimited) {
                        commit('setLimitedESGData', {data: reply, mode: 'benchmark', subMode: 'companies'});
                    }
                    else {
                        if (reply.esg.page > 1) {
                            esgParameters.e.companies = [...esgParameters.e.companies, ...reply.esg.e.companies];
                            esgParameters.s.companies = [...esgParameters.s.companies, ...reply.esg.s.companies];
                            esgParameters.g.companies = [...esgParameters.g.companies, ...reply.esg.g.companies];
                        } 
                        else {
                            esgParameters.e.portfolioTotal = returnElement(reply.esg.e.companies, 'TOTAL');
                            esgParameters.s.portfolioTotal = returnElement(reply.esg.s.companies, 'TOTAL')
                            esgParameters.g.portfolioTotal = returnElement(reply.esg.g.companies, 'TOTAL');
                            esgParameters.e.companies = reply.esg.e.companies;
                            esgParameters.s.companies = reply.esg.s.companies;
                            esgParameters.g.companies = reply.esg.g.companies;
                            esgParameters.e.indicators = reply.esg.e.indicators;
                            esgParameters.s.indicators = reply.esg.s.indicators;
                            esgParameters.g.indicators = reply.esg.g.indicators;
                        }
                        esgParameters.find = reply.esg.find;
                        esgParameters.order = reply.esg.order;
                        esgParameters.orderBy = reply.esg.orderBy;
                        esgParameters.page = reply.esg.page;
                        esgParameters.perPage = reply.esg.perPage;
                        esgParameters.total = reply.esg.total;
                        commit('setESGBenchmark', esgParameters);   
                    }
                    commit('setLoadingESG', false);
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        commit('setLoadingESG', false);
                        commit('setErrorESG', true);
                        throw err;    
                    }
                })
            }
            else 
                commit('setLoadingESG', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setLoadingESG', false);
                commit('setErrorESG', true);
                throw err;    
            }
        })
      },
      async getPortfolioESG({commit, dispatch}, data) {
        commit('setErrorESG', false);
        let esgParameters = data.benchmark ? this.getters["common/getESGBenchmark"] : this.getters["common/getESGPortfolio"];
        let esgPdfParameters = this.getters["common/getESGPdfData"];
        let isLimited = this.getters['profile/getUserCapability'] == 7;
        commit('setLoadingESGResults', true);
        API.get(
            'getESGV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${data.client ? data.client : ''}?page=${data.page}&per_page=${data.perPage ? data.perPage : esgParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${data.benchmark}&limited=${isLimited}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(reply => {
            if (isLimited) {
                commit('setLimitedESGData', {data: reply, mode: data.benchmark ? 'benchmark' : 'portfolio', subMode: 'companies'});
            }
            else {
                if (data.flag && data.flag == 'pdf') {
                    if (reply.esg.page > 1) {
                        esgPdfParameters.e.companies = [...esgPdfParameters.e.companies, ...reply.esg.e.companies];
                        esgPdfParameters.s.companies = [...esgPdfParameters.s.companies, ...reply.esg.s.companies];
                        esgPdfParameters.g.companies = [...esgPdfParameters.g.companies, ...reply.esg.g.companies];
                    } else {
                        esgPdfParameters.e.portfolioTotal = returnElement(reply.esg.e.companies, 'TOTAL');
                        esgPdfParameters.s.portfolioTotal = returnElement(reply.esg.s.companies, 'TOTAL');
                        esgPdfParameters.g.portfolioTotal = returnElement(reply.esg.g.companies, 'TOTAL');
                        esgPdfParameters.e.companies = reply.esg.e.companies;
                        esgPdfParameters.s.companies = reply.esg.s.companies;
                        esgPdfParameters.g.companies = reply.esg.g.companies;
                        esgPdfParameters.e.indicators = reply.esg.e.indicators;
                        esgPdfParameters.s.indicators = reply.esg.s.indicators;
                        esgPdfParameters.g.indicators = reply.esg.g.indicators;
                    }
                    esgPdfParameters.find = reply.esg.find;
                    esgPdfParameters.order = reply.esg.order;
                    esgPdfParameters.orderBy = reply.esg.orderBy;
                    esgPdfParameters.page = reply.esg.page;
                    esgPdfParameters.perPage = reply.esg.perPage;
                    esgPdfParameters.total = reply.esg.total;
                    commit('setESGPdfData', esgPdfParameters);
                } 
                else {
                    if (reply.esg.page > 1) {
                        esgParameters.e.companies = [...esgParameters.e.companies, ...reply.esg.e.companies];
                        esgParameters.s.companies = [...esgParameters.s.companies, ...reply.esg.s.companies];
                        esgParameters.g.companies = [...esgParameters.g.companies, ...reply.esg.g.companies];
                      } else {
                        esgParameters.e.portfolioTotal = returnElement(reply.esg.e.companies, 'TOTAL');
                        esgParameters.s.portfolioTotal = returnElement(reply.esg.s.companies, 'TOTAL')
                        esgParameters.g.portfolioTotal = returnElement(reply.esg.g.companies, 'TOTAL');
                        esgParameters.e.companies = reply.esg.e.companies;
                        esgParameters.s.companies = reply.esg.s.companies;
                        esgParameters.g.companies = reply.esg.g.companies;
                        esgParameters.e.indicators = reply.esg.e.indicators;
                        esgParameters.s.indicators = reply.esg.s.indicators;
                        esgParameters.g.indicators = reply.esg.g.indicators;
                      }
                      esgParameters.find = reply.esg.find;
                      esgParameters.order = reply.esg.order;
                      esgParameters.orderBy = reply.esg.orderBy;
                      esgParameters.page = reply.esg.page;
                      esgParameters.perPage = reply.esg.perPage;
                      esgParameters.total = reply.esg.total;
                      data.benchmark ? commit('setESGBenchmark', esgParameters) : commit('setESGPortfolio', esgParameters);
                }
            }
            commit('setLoadingESGResults', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setLoadingESGResults', false);
                commit('setErrorESG', true);
                throw err;    
            }
        })
      },
      async getInitialESGCountries({commit, dispatch}, data) {
        commit('setErrorESG', false);
        let esgParameters = this.getters["common/getESGCountriesPortfolio"];
        let isLimited = this.getters['profile/getUserCapability'] == 7;
        commit('setLoadingESG', true);
        API.get(
            'getESGCountriesV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${data.client ? data.client : ''}?page=${data.page}&per_page=${esgParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${false}&limited=${isLimited}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then( async reply => {
            if (isLimited) {
                commit('setLimitedESGData', {data: reply, mode: 'portfolio', subMode: 'countries'});
            }
            else {
                if (reply.page > 1) {
                    esgParameters.e.companies = [...esgParameters.e.companies, ...reply.e.companies];
                    esgParameters.s.companies = [...esgParameters.s.companies, ...reply.s.companies];
                    esgParameters.g.companies = [...esgParameters.g.companies, ...reply.g.companies];
                } 
                else {
                    esgParameters.e.portfolioTotal = returnElement(reply.e ? reply.e.companies : 0, 'TOTAL');
                    esgParameters.s.portfolioTotal = returnElement(reply.s ? reply.s.companies : 0, 'TOTAL')
                    esgParameters.g.portfolioTotal = returnElement(reply.g ? reply.g.companies : 0, 'TOTAL');
                    esgParameters.e.companies = reply.e ? reply.e.companies : [];
                    esgParameters.s.companies = reply.s ? reply.s.companies : [];
                    esgParameters.g.companies = reply.g ? reply.g.companies : [];
                    esgParameters.e.indicators = reply.e ? reply.e.indicators : [];
                    esgParameters.s.indicators = reply.s ? reply.s.indicators : [];
                    esgParameters.g.indicators = reply.g ? reply.g.indicators : [];
                }
                esgParameters.find = reply.find ? reply.find : data.find;
                esgParameters.order = reply.order ? reply.order : data.order;
                esgParameters.orderBy = reply.orderBy ? reply.orderBy : data.orderBy;
                esgParameters.page = reply.page ? reply.page : data.page;
                esgParameters.perPage = reply.perPage ? reply.perPage : data.perPage;
                esgParameters.total = reply.total ? reply.total : data.total;
                commit('setESGCountriesPortfolio', esgParameters);
            }
            if (data.benchmark) {
                esgParameters = this.getters["common/getESGCountriesBenchmark"];
                commit('setLoadingESG', true);
                API.get(
                    'getESGCountriesV2'+(await getSessionToken() === null ? 'Shared' : ''),
                    `${data.client ? data.client : ''}?page=${data.page}&per_page=${esgParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${true}&limited=${isLimited}`,
                    {
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }
                ).then(replyBenchmark => {
                    if (isLimited) {
                        commit('setLimitedESGData', {data: replyBenchmark, mode: 'benchmark', subMode: 'countries'});
                    }
                    else {
                        if (reply.page > 1) {
                            esgParameters.e.companies = [...esgParameters.e.companies, ...replyBenchmark.e.companies];
                            esgParameters.s.companies = [...esgParameters.s.companies, ...replyBenchmark.s.companies];
                            esgParameters.g.companies = [...esgParameters.g.companies, ...replyBenchmark.g.companies];
                        } else {
                            esgParameters.e.portfolioTotal = returnElement(replyBenchmark.e ? replyBenchmark.e.companies : 0, 'TOTAL');
                            esgParameters.s.portfolioTotal = returnElement(replyBenchmark.s ? replyBenchmark.s.companies : 0, 'TOTAL')
                            esgParameters.g.portfolioTotal = returnElement(replyBenchmark.g ? replyBenchmark.g.companies : 0, 'TOTAL');
                            esgParameters.e.companies = replyBenchmark.e ? replyBenchmark.e.companies : [];
                            esgParameters.s.companies = replyBenchmark.s ? replyBenchmark.s.companies : [];
                            esgParameters.g.companies = replyBenchmark.g ? replyBenchmark.g.companies : [];
                            esgParameters.e.indicators = replyBenchmark.e ? replyBenchmark.e.indicators : [];
                            esgParameters.s.indicators = replyBenchmark.s ? replyBenchmark.s.indicators : [];
                            esgParameters.g.indicators = replyBenchmark.g ? replyBenchmark.g.indicators : [];
                        }
                        esgParameters.find = replyBenchmark.find ? replyBenchmark.find : data.find;
                        esgParameters.order = replyBenchmark.order ? replyBenchmark.order : data.order;
                        esgParameters.orderBy = replyBenchmark.orderBy ? replyBenchmark.orderBy : data.orderBy;
                        esgParameters.page = replyBenchmark.page ? replyBenchmark.page : data.page;
                        esgParameters.perPage = replyBenchmark.perPage ? replyBenchmark.perPage : data.perPage;
                        esgParameters.total = replyBenchmark.total ? replyBenchmark.total : data.total;
                        commit('setESGCountriesBenchmark', esgParameters);
                    }
                    commit('setLoadingESG', false);
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        commit('setLoadingESG', false);
                        commit('setErrorESG', true);
                        throw err;
                    }
                });
            }
            else
                commit('setLoadingESG', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setLoadingESG', false);
                commit('setErrorESG', true);
                throw err;    
            }
        });
      },
      async getPortfolioESGCountries({commit, dispatch}, data) {
        commit('setErrorESG', false);
        let esgParameters = data.benchmark ? this.getters["common/getESGCountriesBenchmark"] : this.getters["common/getESGCountriesPortfolio"];
        let isLimited = this.getters['profile/getUserCapability'] == 7;
        commit('setLoadingESGResults', true);
        API.get(
            'getESGCountriesV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${data.client ? data.client : ''}?page=${data.page}&per_page=${esgParameters.perPage}&order_by=${data.orderBy}&order=${data.order}&find=${data.find}&benchmark=${data.benchmark}&limited=${isLimited}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(reply => {
            if (isLimited) {
                commit('setLimitedESGData', {data: reply, mode: data.benchmark ? 'benchmark' : 'portfolio', subMode: 'countries'});
            }
            else {
                if (reply.page > 1) {
                    esgParameters.e.companies = [...esgParameters.e.companies, ...reply.e.companies];
                    esgParameters.s.companies = [...esgParameters.s.companies, ...reply.s.companies];
                    esgParameters.g.companies = [...esgParameters.g.companies, ...reply.g.companies];
                } else {
                    esgParameters.e.portfolioTotal = returnElement(reply.e ? reply.e.companies : 0, 'TOTAL');
                    esgParameters.s.portfolioTotal = returnElement(reply.s ? reply.s.companies : 0, 'TOTAL')
                    esgParameters.g.portfolioTotal = returnElement(reply.g ? reply.g.companies : 0, 'TOTAL');
                    esgParameters.e.companies = reply.e ? reply.e.companies : [];
                    esgParameters.s.companies = reply.s ? reply.s.companies : [];
                    esgParameters.g.companies = reply.g ? reply.g.companies : [];
                    esgParameters.e.indicators = reply.e ? reply.e.indicators : [];
                    esgParameters.s.indicators = reply.s ? reply.s.indicators : [];
                    esgParameters.g.indicators = reply.g ? reply.g.indicators : [];
                }
                esgParameters.find = reply.find ? reply.find : data.find;
                esgParameters.order = reply.order ? reply.order : data.order;
                esgParameters.orderBy = reply.orderBy ? reply.orderBy : data.orderBy;
                esgParameters.page = reply.page ? reply.page : data.page;
                esgParameters.perPage = reply.perPage ? reply.perPage : data.perPage;
                esgParameters.total = reply.total ? reply.total : data.total;
                data.benchmark ? commit('setESGCountriesBenchmark', esgParameters) : commit('setESGCountriesPortfolio', esgParameters);
            }
            commit('setLoadingESGResults', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setLoadingESGResults', false);
                commit('setErrorESG', true);
                throw err;    
            }
        })
      },
      clearESG({commit}) {
        let esg = {
            portfolio: {
                companies: {
                    e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    page: 1,
                    total: 1,
                    perPage: 20,
                    order: "asc",
                    find: "",
                    orderBy: "value"
                },
                countries: {
                    e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    page: 1,
                    total: 1,
                    perPage: 20,
                    order: "asc",
                    find: "",
                    orderBy: "value"
                },
            },
            benchmark: {
                companies: {
                    e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    page: 1,
                    total: 1,
                    perPage: 20,
                    order: "asc",
                    find: "",
                    orderBy: "value"
                },
                countries: {
                    e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
                    page: 1,
                    total: 1,
                    perPage: 20,
                    order: "asc",
                    find: "",
                    orderBy: "value"
                },
            }
        };
        commit('setESG', esg);
        commit('setErrorESGCountries', false);
      },
      async getControversyInitial({commit, dispatch}, params) {
        commit('setControversyError', false);
        commit('setLoadingControversy', true);
        let controversyParams = this.getters["common/getControversyDataPortfolio"];
        API.get(
            'getExclusionV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${params.id ? params.id : ''}?page=${params.page}&per_page=${params.perPage ? params.perPage : controversyParams.perPage}&order_by=${params.orderBy}&order=${params.order}&find=${params.find}&benchmark=${false}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(async reply => {
            if (reply.page > 1) {
                controversyParams.results = [...controversyParams.results, ...reply.data];
            } else {
                controversyParams.results = reply.data;
            }
            controversyParams.columns = Object.keys(controversyParams.results[0] ? controversyParams.results[0] : {})?.slice(3).map(e => { return {key: e, value: e}}) || [];
            controversyParams.find = reply.find;
            controversyParams.order = reply.order;
            controversyParams.orderBy = reply.orderBy;
            controversyParams.page = reply.page;
            controversyParams.perPage = reply.perPage;
            controversyParams.total = reply.total;
            commit('setControversyDataPortfolio', controversyParams);
            if (params.benchmark) {
                controversyParams = this.getters["common/getControversyDataBenchmark"];
                API.get(
                    'getExclusionV2'+(await getSessionToken() === null ? 'Shared' : ''),
                    `${params.id ? params.id : ''}?page=${params.page}&per_page=${params.perPage ? params.perPage : controversyParams.perPage}&order_by=${params.orderBy}&order=${params.order}&find=${params.find}&benchmark=${true}`,
                    {
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }
                    ).then(dataBenchmark => {
                        if (dataBenchmark.page > 1) {
                            controversyParams.results = [...controversyParams.results, ...dataBenchmark.data];
                        } else {
                            controversyParams.results = dataBenchmark.data;
                        }
                        controversyParams.columns = Object.keys(controversyParams.results[0] ? controversyParams.results[0] : {})?.slice(3).map(e => { return {key: e, value: e}}) || [];                        
                        controversyParams.find = dataBenchmark.find;
                        controversyParams.order = dataBenchmark.order;
                        controversyParams.orderBy = dataBenchmark.orderBy;
                        controversyParams.page = dataBenchmark.page;
                        controversyParams.perPage = dataBenchmark.perPage;
                        controversyParams.total = dataBenchmark.total;
                        commit('setControversyDataBenchmark', controversyParams);
                        commit('setLoadingControversy', false);
                        commit('setControversyError', false);
                    }).catch(err => {
                        if (err.message && err.message.includes('401')) {
                            dispatch('handleExpiredSession', {}, {root: true});
                        }
                        else {
                            toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                            commit('setLoadingControversy', false);
                            commit('setControversyError', true);
                            throw err;    
                        }
                    })
            }
            else {
                commit('setLoadingControversy', false);
                commit('setControversyError', false);  
            }
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setLoadingControversy', false);
                commit('setControversyError', true);
                throw err;      
            }
        })
      },
      async getControversy({commit, dispatch}, params) {
        commit('setControversyError', false);
        commit('setLoadingControversyResults', true);
        let controversyParams = params.benchmark ? this.getters["common/getControversyDataBenchmark"] : this.getters["common/getControversyDataPortfolio"];
        API.get(
            'getExclusionV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${params.id ? params.id : ''}?page=${params.page}&per_page=${params.perPage ? params.perPage : controversyParams.perPage}&order_by=${params.orderBy}&order=${params.order}&find=${params.find}&benchmark=${params.benchmark}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(async reply => {
            if (reply.page > 1) {
                controversyParams.results = [...controversyParams.results, ...reply.data];
            } else {
                controversyParams.results = reply.data;
            }
            controversyParams.columns = Object.keys(controversyParams.results[0] ? controversyParams.results[0] : {})?.slice(3).map(e => { return {key: e, value: e}}) || [];
            controversyParams.find = reply.find;
            controversyParams.order = reply.order;
            controversyParams.orderBy = reply.orderBy;
            controversyParams.page = reply.page;
            controversyParams.perPage = reply.perPage;
            controversyParams.total = reply.total;
            params.benchmark ? commit('setControversyDataBenchmark', controversyParams) : commit('setControversyDataPortfolio', controversyParams);
            commit('setControversyError', false);
            commit('setLoadingControversyResults', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setLoadingControversyResults', false);
                commit('setControversyError', true);
                throw err;    
            }
        })
      },
      clearPerformanceControversy({commit}) {
        commit('setControversyData', {
            portfolio: {
                results: [],
                columns: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: ""
            },
            benchmark: {
                results: [],
                columns: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: ""
            }
        });
        commit('setControversyError', false);
      },
      async getProductsInitial({commit, dispatch}, params) {
        commit('setProductsError', false);
        commit('setLoadingProducts', true);
        let productsParams = this.getters["common/getPortfolioProductsData"];
        API.get(
            'getProductsV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${params.id ? params.id : ''}?page=${params.page}&per_page=${params.perPage ? params.perPage : productsParams.perPage}&order_by=${params.orderBy}&order=${params.order}&find=${params.find}&benchmark=${false}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(async reply => {
            if (reply.page > 1) {
                productsParams.companies = [...productsParams.companies, ...reply.products];
            } else {
                productsParams.companies = reply.products;
            }
            productsParams.categories = reply.categories;
            productsParams.find = reply.find;
            productsParams.order = reply.order;
            productsParams.orderBy = reply.orderBy;
            productsParams.page = reply.page;
            productsParams.perPage = reply.perPage;
            productsParams.total = reply.total;
            commit('setPortfolioProductsData', productsParams);
            if (params.benchmark) {
                let productsParamsB = this.getters["common/getBenchmarkProductsData"];
                API.get(
                    'getProductsV2'+(await getSessionToken() === null ? 'Shared' : ''),
                    `${params.id ? params.id : ''}?page=${params.page}&per_page=${params.perPage ? params.perPage : productsParamsB.perPage}&order_by=${params.orderBy}&order=${params.order}&find=${params.find}&benchmark=${true}`,
                    {
                        headers: {
                            Authorization: await getSessionToken()
                        }
                    }
                ).then(replyB => {
                    if (replyB.page > 1) {
                        productsParamsB.companies = [...productsParamsB.companies, ...replyB.products];
                    } else {
                        productsParamsB.companies = replyB.products;
                    }
                    productsParamsB.categories = replyB.categories;
                    productsParamsB.find = replyB.find;
                    productsParamsB.order = replyB.order;
                    productsParamsB.orderBy = replyB.orderBy;
                    productsParamsB.page = replyB.page;
                    productsParamsB.perPage = replyB.perPage;
                    productsParamsB.total = replyB.total;
                    commit('setBenchmarkProductsData', productsParamsB);
                    commit('setLoadingProducts', false);
                }).catch(err => {
                    if (err.message && err.message.includes('401')) {
                        dispatch('handleExpiredSession', {}, {root: true});
                    }
                    else {
                        toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                        commit('setProductsError', true);
                        commit('setLoadingProducts', false);
                        throw err;    
                    }
                })
            }
            else {
                commit('setLoadingProducts', false);
            }
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setProductsError', true);
                commit('setLoadingProducts', false);
                throw err;    
            }
        })
      },
      async getProducts({commit, dispatch}, params) {
        commit('setProductsError', false);
        commit('setLoadingProductsResults', true);
        let productsParams = params.benchmark ? this.getters["common/getBenchmarkProductsData"] : this.getters["common/getPortfolioProductsData"];
        API.get(
            'getProductsV2'+(await getSessionToken() === null ? 'Shared' : ''),
            `${params.id ? params.id : ''}?page=${params.page}&per_page=${params.perPage ? params.perPage : productsParams.perPage}&order_by=${params.orderBy}&order=${params.order}&find=${params.find}&category=${params.categories}&benchmark=${params.benchmark}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(reply => {
            if (reply.page > 1) {
                productsParams.companies = [...productsParams.companies, ...reply.products];
            } else {
                productsParams.companies = reply.products;
            }
            productsParams.categories = reply.categories;
            productsParams.find = reply.find;
            productsParams.order = reply.order;
            productsParams.orderBy = reply.orderBy;
            productsParams.page = reply.page;
            productsParams.perPage = reply.perPage;
            productsParams.total = reply.total;
            params.benchmark ? commit('setBenchmarkProductsData', productsParams) : commit('setPortfolioProductsData', productsParams);
            commit('setLoadingProductsResults', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                commit('setProductsError', true);
                commit('setLoadingProductsResults', false);
                throw err;      
            }
        })
      },
      clearPerformanceProducts({commit}) {
        let products = {       
            portfolio: {
                companies: [],
                categories: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: ""
            },
            benchmark: {
                companies: [],
                categories: [],
                page: 1,
                total: 1,
                perPage: 20,
                order: "asc",
                find: "",
                orderBy: ""
            }
        }
        commit('setProductsData', products);
        commit('setProductsError', false);
      },
      async getESGCSV({commit, dispatch}, id) {
        commit('setLoadingEsgCSVURL', true);
        API.get(
            'getESGCSVV2'+(await getSessionToken() === null ? 'Shared' : ''),
            id ? id : '',
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(data => {
          commit('setEsgCSVURL', data.urlDownload);
          commit('setLoadingEsgCSVURL', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.errorCSV'));
                commit('setLoadingEsgCSVURL', false);
                throw err;    
            }
        })
      },
      async getESGCountriesCSV({commit, dispatch}, id) {
        commit('setLoadingEsgCountriesCSVURL', true);
        API.get(
            'getESGCountriesCSVV2'+(await getSessionToken() === null ? 'Shared' : ''),
            id ? id : '',
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(data => {
          commit('setEsgCountriesCSVURL', data.urlDownload);
          commit('setLoadingEsgCountriesCSVURL', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.errorCSV'));
                commit('setLoadingEsgCountriesCSVURL', false);
                throw err;      
            }
        })
      },
      clearEsgCSVURL({commit}) {
        commit('setEsgCSVURL', null);
        commit('setEsgCountriesCSVURL', null);
      },
      async getProductsCSV({commit, dispatch}, id) {
        commit('setLoadingProductsCSVURL', true);
        API.get(
            'getProductsCSVV2'+(await getSessionToken() === null ? 'Shared' : ''),
            id ? id : '',
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(data => {
          commit('setProductsCSVURL', data.urlDownload);
          commit('setLoadingProductsCSVURL', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.errorCSV'));
                commit('setLoadingProductsCSVURL', false);
                throw err;      
            }
        })
      },
      clearPerformanceProductsCSVURL({commit}) {
        commit('setProductsCSVURL', null);
      },
      async getCSV({commit, dispatch}, data) {
        commit('setLoadingCSVURL', true);
        API.get(
            `get${data.section}CSVv2`+(await getSessionToken() === null ? 'Shared' : ''),
            data.section === 'Impact' ? `${data.id ? data.id : ''}?type=${data.type}` : `${data.id ? data.id : ''}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(reply => {
          commit('setCSVURL', reply.urlDownload);
          commit('setLoadingCSVURL', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.errorCSV'));
                commit('setLoadingCSVURL', false);
                throw err;      
            }
        })
      },
      clearCSVURL({commit}) {
        commit('setCSVURL', null);
      },
      async getControversyCSV({commit, dispatch}, id) {
        commit('setLoadingControversyCSVURL', true);
        API.get(
            'getControversyCSVV2'+(await getSessionToken() === null ? 'Shared' : ''),
            id ? id : '',
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(data => {
          commit('setControversyCSVURL', data.urlDownload);
          commit('setLoadingControversyCSVURL', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.errorCSV'));
                commit('setLoadingControversyCSVURL', false);
                throw err;      
            }
        })
      },
      clearPerformanceControversyCSVURL({commit}) {
        commit('setControversyCSVURL', null);
      },
      async getTaxonomyInitialData({commit, state, dispatch}, params) {
        commit('setErrorTaxonomy', false);
        commit('setLoadingTaxonomyData', true);
        API.get(
            'getEuTaxonomy'+(await getSessionToken() === null ? 'Shared' : ''),
            `${params.client ? params.client : ''}?page=${params.page}&benchmark=${false}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(async data => {
          if (data.page === 1)
            commit('setTaxonomyDataPortfolio', data);
          else {
            state.euTaxonomyData.portfolio.results = [...state.euTaxonomyData.portfolio.results, ...data.results];
            state.euTaxonomyData.portfolio.page = data.page;
            state.euTaxonomyData.portfolio.total = data.total;
            state.euTaxonomyData.portfolio.perPage = data.perPage;
            state.euTaxonomyData.portfolio.order = data.order;
            state.euTaxonomyData.portfolio.find = data.find;
            state.euTaxonomyData.portfolio.orderBy = data.orderBy;
          }
          if (params.benchmark) {
            API.get(
                'getEuTaxonomy'+(await getSessionToken() === null ? 'Shared' : ''),
                `${params.client ? params.client : ''}?page=${params.page}&benchmark=${true}`,
                {
                  headers: {
                    Authorization: await getSessionToken()
                  }
                }
            ).then(async data => {
              if (data.page === 1)
                commit('setTaxonomyDataBenchmark', data);
              else {
                state.euTaxonomyData.benchmark.results = [...state.euTaxonomyData.benchmark.results, ...data.results];
                state.euTaxonomyData.benchmark.page = data.page;
                state.euTaxonomyData.benchmark.total = data.total;
                state.euTaxonomyData.benchmark.perPage = data.perPage;
                state.euTaxonomyData.benchmark.order = data.order;
                state.euTaxonomyData.benchmark.find = data.find;
                state.euTaxonomyData.benchmark.orderBy = data.orderBy;
              }
              commit('setLoadingTaxonomyData', false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setErrorTaxonomy', true);
                    commit('setLoadingTaxonomyData', false);
                    throw err;      
                }
            })
          }
          else
            commit('setLoadingTaxonomyData', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                commit('setErrorTaxonomy', true);
                commit('setLoadingTaxonomyData', false);
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;      
            }
        })
      },
      async getTaxonomy({commit, state, dispatch}, params) {
        commit('setErrorTaxonomy', false);
        commit('setLoadingTaxonomyResult', true);
        API.get(
            'getEuTaxonomy'+(await getSessionToken() === null ? 'Shared' : ''),
            `${params.client ? params.client : ''}?page=${params.page}&benchmark=${params.benchmark}`,
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(async data => {
          if (data.page === 1)
            params.benchmark ? commit('setTaxonomyDataBenchmark', data) : commit('setTaxonomyDataPortfolio', data);
          else {
            if (params.benchmark) {
                state.euTaxonomyData.benchmark.results = [...state.euTaxonomyData.benchmark.results, ...data.results];
                state.euTaxonomyData.benchmark.page = data.page;
                state.euTaxonomyData.benchmark.total = data.total;
                state.euTaxonomyData.benchmark.perPage = data.perPage;
                state.euTaxonomyData.benchmark.order = data.order;
                state.euTaxonomyData.benchmark.find = data.find;
                state.euTaxonomyData.benchmark.orderBy = data.orderBy;
            }
            else {
                state.euTaxonomyData.portfolio.results = [...state.euTaxonomyData.portfolio.results, ...data.results];
                state.euTaxonomyData.portfolio.page = data.page;
                state.euTaxonomyData.portfolio.total = data.total;
                state.euTaxonomyData.portfolio.perPage = data.perPage;
                state.euTaxonomyData.portfolio.order = data.order;
                state.euTaxonomyData.portfolio.find = data.find;
                state.euTaxonomyData.portfolio.orderBy = data.orderBy;    
            }
          }
          commit('setLoadingTaxonomyResult', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                commit('setErrorTaxonomy', true);
                commit('setLoadingTaxonomyResult', false);
                throw err;      
            }
        })
      },
      async getTaxonomySummary({commit, dispatch}, id) {
        commit('setErrorTaxonomySummary', false);
        commit('setLoadingTaxonomySummaryData', true);
        API.get(
          'getEuTaxonomySummary'+(await getSessionToken() === null ? 'Shared' : ''),
          `${id ? id : ''}`,
          {
            headers: {
              Authorization: await getSessionToken()
            }
          }
        ).then(res => {
          commit('setTaxonomySummaryData', res);
          commit('setLoadingTaxonomySummaryData', false);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                commit('setErrorTaxonomySummary', true);
                commit('setLoadingTaxonomySummaryData', false);
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;      
            }
        })
      },
      clearTaxonomyData({commit}) {
        let euTaxonomyData = {
            portfolio: {
                results: [],
                page: 1,
                perPage: 20,
                total: 0,
                orderBy: "name",
                order: "desc",
                find: "",
            },
            benchmark: {
                results: [],
                page: 1,
                perPage: 20,
                total: 0,
                orderBy: "name",
                order: "desc",
                find: "",
            }
        };
        commit('setTaxonomyDataPortfolio', euTaxonomyData);
        commit('setTaxonomySummaryData', {});
        commit('setErrorTaxonomy', false);
        commit('setErrorTaxonomySummary', false);
      },
      async getPAIIndicators({commit, dispatch}, data) {
        commit('setErrorPAI', false);
        commit('setLoadingPAIIndicators', true);
        API.get(
            'getPAIIndicatorsData' + (await getSessionToken() === null ? 'Shared' : '') + data.number,
            data.id ? data.id : '',
            {
              headers: {
                Authorization: await getSessionToken()
              }
            }
        ).then(res => {
          commit('setPAIIndicators'+data.number, res);
          setTimeout(() => {
            commit('setLoadingPAIIndicators', false);
          }, 1000);
        }).catch(err => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                commit('setErrorPAI', true);
                commit('setLoadingPAIIndicators', false);
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;      
            }
        })
      },
      clearPAIIndicatorsData({commit}) {
        commit('setPAIIndicators', []);
        commit('setPAIIndicators2', []);
        commit('setPAIIndicators3', []);
        commit('setErrorPAI', false);
      },
      async getExclusionsWatchlistPdf({commit, dispatch}, data) {
        API.get(
            'getExclusionsWatchListPdf',
            data.selectedClient.id,
            {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {}
            }
        ).then((reply) => {
            commit('setPdfExclusionsWatchlist', reply);
        })
        .catch((err) => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;    
            }
        });
      },
      async getRedCompaniesByWeightPdf({commit, dispatch}, data) {
        API.get(
            'getRedCompaniesByWeight',
            data.selectedClient.id,
            {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {}
            }
        ).then((reply) => {
            commit('setRedCompaniesByIndicator', reply);
        })
        .catch((err) => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;    
            }
        });
      },
      async getRedCompaniesByIndicatorPdf({commit, dispatch}, data) {
        API.get(
            'getCompaniesByIndicator',
            data.selectedClient.id,
            {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {}
            }
        ).then((reply) => {
            commit('setRedCompaniesByWeight', reply);
        })
        .catch((err) => {
            if (err.message && err.message.includes('401')) {
                dispatch('handleExpiredSession', {}, {root: true});
            }
            else {
                toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                throw err;    
            }
        });
      },
      resetESGPdfData({commit}) {
          commit('setESGPdfData', {
            e: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
            s: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
            g: {companies: [], indicators: [], selectedIndicators: [], portfolioTotal: null, benchmarkTotal: null},
            find: "",
            order: "desc",
            orderBy: "weight",
            page: 1,
            perPage: 5,
            flag: "pdf",
            total: 1
          });
      },
      resetExclusionsAndWatchlistPDf({commit}) {
        commit('setPdfExclusionsWatchlist', {});
      },
      resetCompaniesByRedIndicator({commit}) {
        commit('setRedCompaniesByIndicator', []);
      },
      resetCompaniesByRedWeight({commit}) {
        commit('setRedCompaniesByWeight', []);
      },
      resetSustainableProducts({commit}) {
        let products = {
            categories: [],
            products: []
        };
          commit('setPortfolioProductsData', products);
          commit('setBenchmarkProductsData', products);
      },
      resetEUTaxonomySummaryData({commit}) {
          commit('setTaxonomySummaryData', {})
      },
      setCSVTab({commit}, data) {
        commit('setCurrentCSVTab', data);
      },
      catchError(err) {
            toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
            throw err;
      },
    },
    getters: {
      getESGPortfolio: state => {
        return state.esg.portfolio.companies;
      },
      getESGBenchmark: state => {
        return state.esg.benchmark.companies;
      },
      getESGCountriesPortfolio: state => {
        return state.esg.portfolio.countries;
      },
      getESGCountriesBenchmark: state => {
        return state.esg.benchmark.countries;
      },
      getESGPdfData: state => {
        return state.esgPdf;
      },
      getLoadingESG: state => {
        return state.loadingESG;
      },
      getLoadingESGResults: state => {
        return state.loadingESGResults;
      },
      getControversyDataPortfolio: state => {
        return state.controversy.portfolio;
      },
      getControversyDataBenchmark: state => {
        return state.controversy.benchmark;
      },
      getLoadingControversy: state => {
        return state.loadingControversy;
      },
      getLoadingControversyResults: state => {
        return state.loadingControversyResults;
      },
      getPortfolioProductsData: state => {
        return state.products.portfolio;
      },
      getBenchmarkProductsData: state => {
        return state.products.benchmark;
      },
      getLoadingProducts: state => {
        return state.loadingProducts;
      },
      getLoadingProductsResults: state => {
        return state.loadingProductsResults;
      },
      getEsgCSVURL: state => {
        return state.esgCSVURL;
      },
      getLoadingEsgCSVURL: state => {
        return state.loadingEsgCSVURL;
      },
      getEsgCountriesCSVURL: state => {
        return state.esgCountriesCSVURL;
      },
      getLoadingEsgCountriesCSVURL: state => {
        return state.loadingEsgCountriesCSVURL;
      },
      getProductsCSVURL: state => {
        return state.productsCSVURL;
      },
      getLoadingProductsCSVURL: state => {
        return state.loadingProductsCSVURL;
      },
      getControversyCSVURL: state => {
        return state.controversyCSVURL;
      },
      getLoadingControversyCSVURL: state => {
        return state.loadingControversyCSVURL;
      },
      getCSVURL: state => {
        return state.csvURL;
      },
      getLoadingCSVURL: state => {
        return state.loadingCSVURL;
      },
      getTaxonomyData: state => {
        return state.euTaxonomyData;
      },
      getTaxonomySummaryData: state => {
        return state.euTaxonomySummary;
      },
      getLoadingTaxonomyData: state => {
        return state.loadingTaxonomyData;
      },
      getLoadingTaxonomySummaryData: state => {
        return state.loadingTaxonomySummaryData;
      },
      getLoadingTaxonomyResult: state => {
        return state.loadingTaxonomyResult;
      },
      getErrorTaxonomy: state => {
        return state.errorTaxonomy;
      },
      getErrorTaxonomySummary: state => {
        return state.errorTaxonomySummary;
      },
      getPdfExclusionsAndWatchlist: state => {
          return state.exclusionsWatchlistPdf;
      },
      getRedCompaniesByIndicator: state => {
          return state.redCompaniesByIndicator;
      },
      getRedCompaniesByWeight: state => {
        return state.redCompaniesByWeight;
      },
      getSustainableProductsPdf: state => {
        let sustainableProducts = JSON.parse(JSON.stringify(state.products.products));
        sustainableProducts.sort((a,b) => {
            if (parseFloat(b.weight) > parseFloat(a.weight)) {
                return 1;
            }
            if (parseFloat(b.weight) < parseFloat(a.weight)) {
                return -1;
            }
            if (parseFloat(b.weight) === parseFloat(a.weight)) {
                return 0;
            }
        });
        if (sustainableProducts.length >= 5) {
            sustainableProducts = sustainableProducts.splice(0, 5);
        }
        sustainableProducts.forEach(company => {
            company.products.sort((a,b) => {
                if (parseFloat(b.sustainableScore) > parseFloat(a.sustainableScore)) {
                    return 1;
                }
                if (parseFloat(b.sustainableScore) < parseFloat(a.sustainableScore)) {
                    return -1;
                }
                if (parseFloat(b.sustainableScore) === parseFloat(a.sustainableScore)) {
                    return 0;
                }
            });
            if (company.products.length >= 3) {
                company.products = company.products.splice(0, 3);
            }
        });
        return sustainableProducts;
      },
      getProductsError: state => {
          return state.productsError;
      },
      getErrorESG: state => {
        return state.errorESG;
      },
      getControversyError: state => {
        return state.controversyError
      },
      getPaiDetailLoading: state => {
        return state.paiDetailLoading;
      },
      getPAIDetailLoadingResults: state => {
        return state.paiDetailLoadingResults;
      },
      getPaiDetailError: state => {
        return state.paiDetailError;
      },
      getPAIDetailPortfolioData: state => {
        return state.paiDetail.portfolio;
      },
      getPAIDetailBenchmarkData: state => {
        return state.paiDetail.benchmark;
      },
      getCSVTab: state => {
        return state.csvTab;
      },
      getMinPAIYear: state => {
        return state.minPAIYear;
      },
      getESGLimitedDataPortfolio: state => {
        return state.esgLimited.portfolio.companies;
      },
      getESGLimitedDataPortfolioCountries: state => {
        return state.esgLimited.portfolio.countries;
      },
      getESGLimitedDataBenchmark: state => {
        return state.esgLimited.benchmark.companies;
      },
      getESGLimitedDataBenchmarkCountries: state => {
        return state.esgLimited.benchmark.countries;
      }
    }
}

export default common