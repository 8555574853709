import {API} from '../http/api';
import {getSessionToken} from "../http/auth";
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();
const shareClient = {
    namespaced: true,
    state: {
        selectedClient: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            id: '',
            portfolio: {
                investmentAmount: null,
                investmentDay: '',
                updatedAt: null,
                portfolio: {
                    items: [],
                    cash: ''
                },
                benchmark: {
                    items: [],
                    cash: ''
                }
            }
        },
        sharingStatus: {},
        expired: false,
        accessLevel: undefined,
        estimatedData: false
    },
    mutations: {
        setSelectedClient(state, payload) {
            state.selectedClient = payload;
        },
        setSharingStatus(state, payload) {
            state.sharingStatus = payload
        },
        setExpired(state, payload) {
            state.expired = payload;
        },
        setAccessLevel(state, payload) {
            state.accessLevel = payload
        },
        setEstimatedData(state, payload) {
            state.estimatedData = payload;
        }
    },
    actions: {
        async shareClient({commit, dispatch}, id){
            API.get('shareClient', id, {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {}
            })
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    let results = {};
                    commit("setSharingStatus", results);
                } else {
                    toast.error(i18n.global.t('alert.error.cantShareClient'));
                }
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        },
        async selectClient({commit, dispatch}, id) {
            API.get('getSharedClientV2', id)
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    let results = {};
                    results.data = reply;
                    commit('setAccessLevel', results.data.accessLevel)
                    commit("setSelectedClient", results);
                } else {
                    toast.error(i18n.global.t('alert.error.cantLoadPerformanceData'));
                }
            })
            .catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        },
        setEstimatedData({commit}, data) {
            commit('setEstimatedData', data);
        }
    },
    getters: {
        getSelectedClient: state => {
            return state.selectedClient;
        },
        getSharingStatus: state => {
            return state.sharingStatus
        },
        getExpired: state => {
            return state.expired;
        },
        getPortfolioHasBenchmark: state => {
            return state.selectedClient?.data.portfolio?.hasBenchmark; 
        },
        getAccessLevel: state => {
            return Number(state.accessLevel);
        },
        getPortfolioDayOfFirstInvestment: state => {
            return state.selectedClient?.data.portfolio?.investmentDay
        },
        getEstimatedData: state => {
            return state.estimatedData;
        }
    }
}

export default shareClient