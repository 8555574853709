import { createI18n } from 'vue-i18n'
import en from './lang/en.json'
import it from './lang/it.json'

const messages = {
    en: en,
    it: it
}

const getCookie = () => {
    let lang = document.cookie.match('(^|;)\\s*' + 'lang' + '\\s*=\\s*([^;]+)')?.pop() || ''
    if(!lang) {
        lang = navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0];
    }
    return lang
}

const i18n = createI18n({
    locale: getCookie(),
    fallbackLocale: 'en',
    messages,
})

export default i18n;