import axios from "axios";
import {Amplify} from 'aws-amplify'
const API_ROOT = `https://${process.env.VUE_APP_API_NODE}.execute-api.${process.env.VUE_APP_API_REGION}.amazonaws.com/${process.env.VUE_APP_API_STAGE}`;
const API_ROOT_PROFILE = `https://${process.env.VUE_APP_API_NODE}.execute-api.${process.env.VUE_APP_API_REGION}.amazonaws.com/${process.env.VUE_APP_API_STAGE}/profile`;
const API_LOAD_BALANCER_V2 = `https://api.${process.env.VUE_APP_API_STAGE}.physisinvestment.com/v2`;
const API_ROOT_PROFILE_V2 = `https://${process.env.VUE_APP_API_NODE}.execute-api.${process.env.VUE_APP_API_REGION}.amazonaws.com/${process.env.VUE_APP_API_STAGE}/v2`;

Amplify.configure({
    Auth:{Cognito:{
            userPoolId: process.env.VUE_APP_USERPOOL_ID,
            userPoolClientId: process.env.VUE_APP_USERPOOL_CLIENT_ID,
        }},
    });

    const ApiEndpoints= {
        endpoints: [
            {
                name: 'signUpApi',
                endpoint: `${API_ROOT}/api/auth/signup`
            },
            {
                name: 'checkUserExists',
                endpoint: `${API_ROOT}/api/auth/emailExists`
            },
            {
                name: 'loginAPI',
                endpoint: `${API_ROOT}/api/auth/login`
            },
            {
                name: 'isUserLoggedIn',
                endpoint: `${window.location.protocol}//${window.location.host}/isUserLoggedIn`
            },
            {
                name: 'cookieAuthorizeEndpoint',
                endpoint: `${window.location.protocol}//${window.location.host}/authorize`
            },
            {
                name: 'deleteAccount',
                endpoint: `${API_ROOT}/auth/delete-me`
            },
            {
                name: 'getUserProfile',
                endpoint: `${API_ROOT}/api/getUserData`
            },
            {
                name: 'updateUserProfile',
                endpoint: `${API_ROOT}/api/updateUserProfile`
            },
            {
                name: 'imageUpload',
                endpoint: `${API_ROOT}/api/uploadimage`
            },
            {
                name: 'getProfileImg',
                endpoint: `${API_ROOT}/api/getProfileImg/`
            },
            {
                name: 'getPortfolioByToken',
                endpoint: `${API_ROOT_PROFILE}/portfolio/`
            },
            {
                name: 'getPortfolioDailyPerformanceByToken',
                endpoint: `${API_ROOT_PROFILE}/portfolio/dailyPerformance`
            },
            {
                name: 'getPerformanceByToken',
                endpoint: `${API_ROOT_PROFILE}/portfolio/performance`
            },
            {
                name: 'getAccessToken',
                endpoint: `${API_ROOT_PROFILE}/banks/getAccessToken`
            },
            {
                name: 'getBanks',
                endpoint: `${API_ROOT_PROFILE}/banks/getAll`
            },
            {
                name: 'deleteBank',
                endpoint: `${API_ROOT_PROFILE}/banks/delete`
            },
            {
                name: 'softDeleteBank',
                endpoint: `${API_ROOT_PROFILE}/banks/softDelete`
            },
            {
                name: 'checkISIN',
                endpoint: `${API_ROOT_PROFILE}/funds/isin/`
            },
            {
                name: 'portfolioAdvisorTest',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/`
            },
            {
                name: 'getPortfolioAdvisorClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/`,
            },
            {
                name: 'savePortfolioAdvisorClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/`
            },
            {
                name: 'updatePortfolioAdvisorClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/`
            },
            {
                name: 'saveAdvisorClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/`
            },
            {
                name: 'saveAdvisorCompanyInfo',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/company/`
            },
            {
                name: 'getAdvisorCompanyInfo',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/company/`
            },
            {
                name: 'getPortfolioByClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/portfolio/`,
            },
            {
                name: 'getPerformanceByClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/performance/`
            },
            {
                name: 'deleteAdvisorClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/`
            },
            {
                name: 'setClientImg',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/setImage/`,
            },
            {
                name: 'getTestPortfolio',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/portfolio/`,
            },
            {
                name: 'getTestPerformance',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/performance/`,
            },
            {
                name: 'getTestESG',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/esg`,
            },
            {
                name: 'getTestESGCountries',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/advisor/test/esg/countries`,
            },
            {
                name: 'getTestESGCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/esg/csv/`,
            },
            {
                name: 'getTestCountriesESGCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/esg/countries/csv/`,
            },
            {
                name: 'getClientESG',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/esg/`,
            },
            {
                name: 'getClientESGCountries',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/advisor/client/esg/countries/`,
            },
            {
                name: 'getClientESGCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/esg/csv/`,
            },
            {
                name: 'getClientCountriesESGCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/esg/countries/csv/`,
            },
            {
                name: 'getESG',
                endpoint: `${API_ROOT}/profile/portfolio/esg/`,
            },
            {
                name: 'getTestExclusion',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/exclusion/`,
            },
            {
                name: 'getTestExclusionCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/exclusion/csv/`,
            },
            {
                name: 'getClientExclusion',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/exclusion/`,
            },
            {
                name: 'getClientExclusionCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/exclusion/csv/`,
            },
            {
                name: 'uploadCompanyLogo',
                endpoint: `${API_ROOT}/profile/portfolio/advisor/company/setLogo/`,
            },
            {
                name: 'screening',
                endpoint: `${API_ROOT_PROFILE}/portfolio/screening/`,
            },
            {
                name: 'getSecuritiesScreeningFundCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/screening/csv`,
            },
            {
                name: 'getTestImpactProducts',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/products/`
            },
            {
                name: 'getTestImpactProductsCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/products/csv/`
            },
            {
                name: 'getClientImpactProducts',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/products/`
            },
            {
                name: 'getClientImpactProductsCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/products/csv/`
            },
            {
                name: 'checkHoldingsISIN',
                endpoint: `${API_ROOT_PROFILE}/holdings/isin/`,
            },
            {
                name: 'holdingsScreening',
                endpoint: `${API_ROOT_PROFILE}/holdings/screenings/`,
            },
            {
                name: 'shareClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/share/`,
            },
            {
                name: 'getSharedClient',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/`,
            },
            {
                name: 'getSharedClientESG',
                endpoint: `${API_ROOT_PROFILE}/profile/portfolio/client/esg/`,
            },
            {
                name: 'getSharedClientESGCountries',
                endpoint: `${API_LOAD_BALANCER_V2}/profile/portfolio/client/esg/countries/`,
            },
            {
                name: 'getSharedClientESGCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/esg/csv/`,
            },
            {
                name: 'getSharedClientCountriesESGCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/esg/countries/csv/`,
            },
            {
                name: 'getSharedClientExclusion',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/exclusion/`,
            },
            {
                name: 'getSharedClientExclusionCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/exclusion/csv/`,
            },
            {
                name: 'getSharedClientProducts',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/products/`,
            },
            {
                name: 'getSharedClientProductsCSV',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/products/csv/`,
            },
            {
                name: 'getSharedClientPortfolio',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/portfolio/`,
            },
            {
                name: 'getSharedClientPerformance',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/performance/`,
            },
            {
                name: 'getSharedClientDailyPerformance',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/dailyPerformance/`,
            },
            {
                name: 'getClientDailyPerformance',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/dailyPerformance/`
            },
            {
                name: 'getTestDailyPerformance',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/dailyPerformance`
            },
            {
                name: 'fetchTestPaginatedHoldings',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/test/holdings`
            },
            {
                name: 'fetchClientPaginatedHoldings',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/holdings/`
            },
            {
                name: 'fetchSharedClientPaginatedHoldings',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/holdings/`
            },
            {
                name: 'fetchCompanyTabOptions',
                endpoint: `${API_ROOT_PROFILE}/companies/screenings`
            },
            {
                name: 'screeningByCompany',
                endpoint: `${API_ROOT_PROFILE}/companies/screenings`
            },
            {
                name: 'getSecuritiesScreeningCompaniesCSV',
                endpoint: `${API_ROOT_PROFILE}/companies/screenings/csv`,
            },
            {
                name: 'fetchCountriesTabOptions',
                endpoint: `${API_ROOT_PROFILE}/countries/screenings`
            },
            {
                name: 'screeningByCountry',
                endpoint: `${API_ROOT_PROFILE}/countries/screenings`
            },
            {
                name: 'getSecuritiesScreeningCountriesCSV',
                endpoint: `${API_ROOT_PROFILE}/countries/screenings/csv`,
            },
            {
                name: 'screeningByCountry',
                endpoint: `${API_ROOT_PROFILE}/countries/screenings`
            },
            {
                name: 'getClientPAI',
                endpoint: `${API_ROOT_PROFILE}/portfolio/advisor/client/pai/`
            },
            {
                name: 'getSharedClientPAI',
                endpoint: `${API_ROOT_PROFILE}/portfolio/client/pai/`
            },
            // V2
            {
                name: 'clientV2',
                endpoint: `${API_ROOT_PROFILE_V2}/client/`
            },
            {
                name: 'securitiesExistV2',
                endpoint: `${API_ROOT_PROFILE_V2}/id/exists`
            },
            {
                name: 'portfolioV2',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/`
            },
            {
                name: 'fetchPaginatedHoldingsV2',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/holdings/`
            },
            {
                name: 'providerRetrieveV2',
                endpoint: `${API_ROOT_PROFILE_V2}/provider/retrieve/`
            },
            {
                name: 'recalculatePortfolioV2',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/calculate/`
            },
            {
                name: 'getPortfolioByClientV2',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/general/`,
            },
            {
                name: 'getESGV2',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/esg/`
            },
            {
                name: 'getESGCountriesV2',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/esg/countries/`
            },
            {
                name: 'getDailyPerformance',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/daily-performance/`
            },
            {
                name: 'getExclusionV2',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/exclusion/`
            },
            {
                name: 'getPortfolioPerformance',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/performance/`
            },
            {
                name: 'getProductsV2',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/products/`
            },
            {
                name: 'getImpactIndicators',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/impact-indicators/`
            },
            {
                name: 'getImpactIndicatorsSummary',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/impact-summary/`
            },
            {
                name: 'getImpactIndicatorsDetail',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/impact-detail/`
            },
            {
                name: 'calculateImpactV2',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/impact/`
            },
            {
                name: 'getLastPortfolio',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/`
            },
            //share Client routes
            {
                name: 'getSharedClientV2',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/client/`
            },
            {
                name: 'fetchPaginatedHoldingsV2Shared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/holdings/`
            },
            {
                name: 'getPortfolioByClientV2Shared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/general/`,
            },
            {
                name: 'getESGV2Shared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/esg/`
            },
            {
                name: 'getESGCountriesV2Shared',
                endpoint: `${API_LOAD_BALANCER_V2}/shared/esg/countries/`
            },
            {
                name: 'getDailyPerformanceShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/daily-performance/`
            },
            {
                name: 'getExclusionV2Shared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/exclusion/`
            },
            {
                name: 'getPortfolioPerformanceShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/performance/`
            },
            {
                name: 'getProductsV2Shared',
                endpoint: `${API_LOAD_BALANCER_V2}/shared/products/`
            },
            {
                name: 'getImpactIndicatorsShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/impact-indicators/`
            },
            {
                name: 'getImpactIndicatorsSummaryShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/impact-summary/`
            },
            {
                name: 'getImpactIndicatorsDetailShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/impact-detail/`
            },
            //billing
            {
                name: 'billingPlans',
                endpoint: `${API_ROOT_PROFILE_V2}/billing/plans/`
            },
            {
                name: 'billingCoupon',
                endpoint: `${API_ROOT_PROFILE_V2}/billing/coupon/`
            },
            {
                name: 'billingSession',
                endpoint: `${API_ROOT_PROFILE_V2}/billing/session/`
            },
            {
                name: 'billingCustomer',
                endpoint: `${API_ROOT_PROFILE_V2}/billing/customer/`
            },
            {
                name: 'deleteSubscription',
                endpoint: `${API_ROOT_PROFILE_V2}/billing/subscription`
            },
            {
                name: 'portalSession',
                endpoint: `${API_ROOT_PROFILE_V2}/billing/portal-session`
            },
            //taxonomy
            {
                name: 'getEuTaxonomy',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/taxonomy/`
            },
            {
                name: 'getEuTaxonomySummary',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/taxonomy-summary/`
            },
            {
                name: 'getEuTaxonomyShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/taxonomy/`
            },
            {
                name: 'getEuTaxonomySummaryShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/taxonomy-summary/`
            },
            // pdf specific routes
            {
                name: 'getDailyPerformancePDF',
                endpoint: `${API_ROOT_PROFILE_V2}/pdf/daily-performance/`
            },
            {
                name: 'getCountryPortfolioAllocation',
                endpoint: `${API_ROOT_PROFILE_V2}/pdf/country-allocation/`
            },
            {
                name: 'getExclusionsWatchListPdf',
                endpoint: `${API_ROOT_PROFILE_V2}/pdf/exclusion-data/`
            },
            {
                name: 'getRedCompaniesByWeight',
                endpoint: `${API_ROOT_PROFILE_V2}/pdf/red-exclusion/`
            },
            {
                name: 'getCompaniesByIndicator',
                endpoint: `${API_ROOT_PROFILE_V2}/pdf/red-indicator/`
            },
            // PAI indicator routes
            {
                name: 'calculatePAIIndicator',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/pai/`
            },
            {
                name: 'getPAIIndicatorsData',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/pai/`
            },
            {
                name: 'getPAIIndicatorsDataShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/pai/`
            },
            {
                name: 'getExchangeRate',
                endpoint: `${API_ROOT_PROFILE_V2}/provider/get-exchange-rate`
            },
            {
                name: 'calculatePAIIndicator2',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/pai-2/`
            },
            {
                name: 'getPAIIndicatorsData2',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/pai-2/`
            },
            {
                name: 'getPAIIndicatorsDataShared2',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/pai-2/`
            },
            {
                name: 'calculatePAIIndicator3',
                endpoint: `${API_LOAD_BALANCER_V2}/portfolio/pai-3/`
            },
            {
                name: 'getPAIIndicatorsData3',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/pai-3/`
            },
            {
                name: 'getPAIIndicatorsDataShared3',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/pai-3/`
            },
            {
                name: 'getPAIDetail',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/pai-detail/`
            },
            {
                name: 'getPAIDetailShared',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/pai-detail/`
            },
            {
                name: 'getPAIDetail2',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/pai-detail-2/`
            },
            {
                name: 'getPAIDetailShared2',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/pai-detail-2/`
            },
            {
                name: 'getPAIDetail3',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/pai-detail-3/`
            },
            {
                name: 'getPAIDetailShared3',
                endpoint: `${API_ROOT_PROFILE_V2}/shared/pai-detail-3/`
            },
            // Screening V2
            {
                name: 'saveScreeningTemplate',
                endpoint: `${API_ROOT_PROFILE_V2}/screening/research/`
            },
            {
                name: 'updateScreeningTemplate',
                endpoint: `${API_ROOT_PROFILE_V2}/screening/research/`
            },
            {
                name: 'getScreeningTemplates',
                endpoint: `${API_ROOT_PROFILE_V2}/screening/research/`
            },
            {
                name: 'deleteScreeningTemplate',
                endpoint: `${API_ROOT_PROFILE_V2}/screening/research/`
            },
            {
                name: 'uploadCSVTemplate',
                endpoint: `${API_ROOT_PROFILE_V2}/screening/research/`
            },
            {
                name: 'getFundScreeningFilters',
                endpoint: `${API_ROOT_PROFILE_V2}/screening/fund`
            },
            {
                name: 'runFundScreening',
                endpoint: `${API_ROOT_PROFILE_V2}/screening/fund`
            },
            // CSV
            {
                name: 'getESGCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/esg/`
            },
            {
                name: 'getESGCountriesCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/esg/countries/`
            },
            {
                name: 'getProductsCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/products/`
            },
            {
                name: 'getControversyCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/exclusion/`
            },
            {
                name:'getFundScreeningCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/screening/fund`
            },
            {
                name:'getCompanyScreeningCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/screening/company`
            },
            {
                name:'getCountryScreeningCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/screening/country`
            },
            {
                name:'getImpactCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/impact/`
            },
            {
                name:'getTaxonomyCSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/taxonomy/`
            },
            {
                name:'getPAICSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/pai/`
            },
            {
                name:'getPAI2CSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/pai-2/`
            },
            {
                name:'getPAI3CSVv2',
                endpoint: `${API_ROOT_PROFILE_V2}/csv/pai-3/`
            },
            //VERSION CHECK
            {
                name: 'getVersionV2',
                endpoint: `${API_ROOT_PROFILE_V2}/version`
            },
            {
                name: 'sendChatPrompt',
                endpoint: `${API_LOAD_BALANCER_V2}/chat`
            },
            {
                name: 'setPAIUpdateDate',
                endpoint: `${API_ROOT_PROFILE_V2}/portfolio/pai-date-update/`
            },
            // CUSTOMIZATION
            {
                name: 'getImpactCustomizationData',
                endpoint: `${API_ROOT_PROFILE_V2}/settings/impact`
            },
            {
                name: 'getImpactConfiguration',
                endpoint: `${API_ROOT_PROFILE_V2}/settings`
            },
            {
                name: 'saveDefaultImpactIndicators',
                endpoint: `${API_ROOT_PROFILE_V2}/settings`
            },
            {
                name: 'updateDefaultImpactIndicators',
                endpoint: `${API_ROOT_PROFILE_V2}/settings`
            },
            //CHATBOT
            {
                name: 'getChat',
                endpoint: `${API_ROOT_PROFILE_V2}/chat`
            },
            {
                name: 'sendChat',
                endpoint: `${API_ROOT_PROFILE_V2}/chat`            
            },
            {
                name:'getChatStatus',
                endpoint: `${API_ROOT_PROFILE_V2}/chat/status`
            },
            {
                name:'testChat',
                endpointMap : `http://localhost:8000/chat`
            }

        ]
    }
    const endpointMap = new Map(ApiEndpoints.endpoints.map(endpoint => [endpoint.name, endpoint.endpoint]))

    async function get(endpoint, url , options){
        const response = await axios.get(endpointMap.get(endpoint)+url,options)
        return response.data;
    }

    async function post(endpoint, url, options) {
        console.log("ASD", endpointMap.get(endpoint) + url, options.body, { headers: options.headers })
        const respuesta = await axios.post(endpointMap.get(endpoint) + url, options.body, { headers: options.headers });
        return respuesta.data;
    }

    
    
    async function put(endpoint, url , options){
        const respuesta = await axios.put(endpointMap.get(endpoint) + url, options.body, { headers: options.headers });
        return respuesta.data;    }  

    async function patch(endpoint, url , options){
        const respuesta = await axios.patch(endpointMap.get(endpoint) + url, options.body, { headers: options.headers });
        return respuesta.data;
    }

    async function del(endpoint, url , options){
        const respuesta = await axios.delete(endpointMap.get(endpoint) + url, { data: options.body, headers: options.headers,});  
        return respuesta.data;
        }



const API = {get:get, post:post, put:put, patch:patch, del:del}
export {API}
