<template>
    <router-link
        v-bind="$props"
        custom
        v-slot="{ isActive, href, navigate }"
    >
        <a
            class="inner-link"
            v-bind="$attrs"
            :href="href"
            @click="navigate"
            :class="[isActive ? activeClass : inactiveClass + ' black-text', disabled ? 'disabled' : '']"
            >
            <slot />
        </a>
    </router-link>
</template>

<script>
import { RouterLink } from 'vue-router'

export default {
    name: 'CustomLink',
    inheritAttrs: false,

    props: {
        ...RouterLink.props,
        inactiveClass: String,
        disabled: Boolean
    },
}
</script>

<style lang="scss" scoped>

.inner-link {
    text-decoration: none;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor:pointer
    }
}


</style>