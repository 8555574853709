<template>
    <div id="terms-section" :class="[userLogged ? 'h-100' : 'mt-5']" class="d-flex">
        <div class="d-flex flex-column w-100" :class="[userLogged ? 'h-100' : '']">
            <div :class="[userLogged ? 'justify-content-end h-100 align-items-center' : 'justify-content-start']" class="d-flex flex-row">
                <p class="mb-0">
                    <a class="footer-link poppins-light me-2" href="https://physisinvestment.com/terms">{{$t("content.terms")}}</a>
                    <a class="footer-link poppins-light ms-2" href="https://physisinvestment.com/terms">{{$t("content.privacyPolicy")}}</a>
                </p>
            </div>
            <br v-if="!userLogged">
            <small v-if="!userLogged" class="poppins-light">{{$t("content.termsDescription1")}}</small><br v-if="!userLogged"><br v-if="!userLogged">
            <small v-if="!userLogged" class="poppins-light">{{$t("content.termsDescription2")}}</small><br v-if="!userLogged"><br v-if="!userLogged">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        userLogged: Boolean
    }
}
</script>

<style lang="scss" scoped>

#terms-section {
    color: #fff;
    .footer-link {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

</style>