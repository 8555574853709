import { createStore } from 'vuex'
import auth from './auth'
import profile from './profile'
import portfolioHub from './portfoliohub'
import dataHub from './datahub'
import performance from './performance'
import common from './common'
import shareClient from './shareclient'
import billing from './billing'
import pai from './pai'
import pdf from './pdf'
import chat from './chat'
import customization from './customization'
import {API} from '../http/api'
import router from "../router"
import { useToast } from 'vue-toastification'
import i18n from '../locales/index'

const version = process.env.VER;
const toast = useToast();

export default createStore({
    state: {
        alert: {
            message: '',
            isSuccess: false,
            triggered: false,
            item: '',
            selfDismiss: false,
            customMessage: false
        },
        alertStackPointer: 0,
        sharing: false,
        checkingCapabilityStatus: false,
        supportEmail: 'physis@physisinvestment.com',
        hideArrowBtn: false,
        isMenuOpen: true,
        isDiffVer: false
    },
    mutations: {
        setAlert(state, payload) {
            state.alert = payload
        },
        setSharing(state, payload){
            state.sharing = payload
            if(payload){
                this.dispatch('profile/setUserCapability', 4);
            }
        },
        setCheckingCapabilityStatus(state, payload){
            state.checkingCapabilityStatus = payload
        },
        setHideArrowBtn(state, payload) {
            state.hideArrowBtn = payload
        },
        setIsMenuOpen(state, payload) {
            state.isMenuOpen = payload
        },
        setAlertStackPointer(state, payload) {
            state.alertStackPointer = payload;
        },
        setIsDiffVer(state, payload) {
            state.isDiffVer = payload;
        }
    },
    actions: {
        triggerAlert({commit}, alert) {
            commit('setAlertStackPointer', this.getters["getAlertStackPointer"] + 1);
            commit('setAlert', alert);
            if(alert.selfDismiss === undefined || alert.selfDismiss !== false) {
                setTimeout(() => {
                    if(this.getters["getAlertStackPointer"] < 2)
                    commit('setAlert', {
                        message: '',
                        isSuccess: false,
                        triggered: false,
                        item: '',
                    })
                    commit('setAlertStackPointer', 0);
                }, 5000)
            }
        },
        setSharing({commit}, value){
            commit('setSharing', value);
        },
        setCheckingCapabilityStatus({commit}, value){
            commit('setCheckingCapabilityStatus', value);
        },
        setHideArrowBtn({commit}, data) {
            commit('setHideArrowBtn', data);
        },
        setIsMenuOpen({commit}, data) {
            commit('setIsMenuOpen', data);
        },
        getVersion({commit}) {
            API.get(
                'getVersionV2',
                ''
            ).then(reply => {
                if (reply !== version)
                    commit('setIsDiffVer', true);
            }).catch(err => {
                throw err;
            })
        },
        handleExpiredSession() {
            router.push({
                name: 'Login',
            });
            setTimeout(() => {
                toast.error(i18n.global.t('alert.error.sessionExpired'));
            });    
        }   
    },
    getters: {
        getAlert: state => {
            return state.alert;
        },
        getSharing: state => {
            return state.sharing;
        },
        getCheckingCapabilityStatus: state => {
            return state.checkingCapabilityStatus;
        },
        getSupportEmail: state => {
            return state.supportEmail
        },
        getHideArrowBtn: state => {
            return state.hideArrowBtn;
        },        
        getIsMenuOpen: state => {
            return state.isMenuOpen;
        },
        getAlertStackPointer: state => {
            return state.alertStackPointer;
        },
        getIsDiffVer: state => {
            return state.isDiffVer;
        }
    },
    modules: {
        auth: auth,
        profile: profile,
        portfolioHub: portfolioHub,
        dataHub: dataHub,
        performance: performance,
        common: common,
        shareClient: shareClient,
        billing: billing,
        pdf: pdf,
        chat: chat,
        pai: pai,
        customization: customization
    }
})
