import {API} from '../http/api';
import {getSessionToken} from "../http/auth";
import { useToast } from 'vue-toastification';
import i18n from '../locales/index';

const toast = useToast();

const billing = {
    namespaced: true,
    state: {
        pricingPlans: {},
        processingOrder: false,
        hasSubscriptions: false,
        subscriptionStatus: 0,
        session: {},
        appliedCoupon: false,
        couponError: 0,
        coupon: {},
        customer: {},
        cancelled: false,
        cancellationErrors: false,
        geoPluginData: {},
        portalSession: {},
        requestingPortalSession: false
    },
    mutations: {
        setPricingPlans(state, payload) {
            state.pricingPlans = payload;
        },
        setProcessingOrder(state, payload) {
            state.processingOrder = payload;
        },
        setSubscriptionStatus(state, payload) {
            state.subscriptionStatus = payload;
        },
        setSession(state, payload) {
            state.session = payload;
        },
        setAppliedCoupon(state, payload) {
            state.appliedCoupon = payload;
        },
        setCoupon(state, payload) {
           state.coupon = payload;
        },
        setCouponError(state, payload) {
            state.couponError = payload;
        },
        setCustomer(state, payload) {
            state.customer = payload;
        },
        setGeoPluginData(state, payload){
            state.geoPluginData = payload;
        },
        setCancelled(state, payload){
            state.cancelled = payload;
        },
        setCancellationErrors(state, payload){
            state.cancellationErrors = payload;
        },
        setHasSubscriptions(state, payload){
            state.hasSubscriptions = payload;
        },
        setPortalSession(state, payload){
            state.portalSession = payload
        },
        setRequestingPortalSession(state, payload){
            state.requestingPortalSession = payload
        },
    },
    actions: {
        async getPricingPlans({commit, dispatch}){
            API.get('billingPlans', '', {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {}
            })
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    if(reply instanceof Array && reply.length && reply[0].hasDiscount){
                        commit("setAppliedCoupon", true);
                    }
                    if(reply instanceof Array && reply.length && reply[0].hasSubscriptions){
                        /*
                        subscription statuses:
                        1. Invoice pending
                        2. Active
                        3. Inactive
                        4. Pending Cancellation
                        5. Past Due
                        6. Unpaid
                        * */
                        commit('setHasSubscriptions', true);
                        commit("setSubscriptionStatus", reply[0].subscriptionStatus);
                        switch (reply[0].subscriptionStatus) {
                            case 1:
                                commit("setCancelled", false);
                                break;
                            case 2:
                                commit("setCancelled", false);
                                break;
                            case 3:
                                commit("setCancelled", true);
                                break;
                            case 4:
                                commit("setCancelled", true);
                                break;
                            case 5:
                                commit("setCancelled", false);
                                break;
                            case 6:
                                commit("setCancelled", true);
                                break;
                            default:
                                commit("setCancelled", true);
                        }
                    }else{
                        commit('setHasSubscriptions', false);
                        commit("setCancelled", false);
                        commit("setSubscriptionStatus", 0);
                    }
                    commit("setPricingPlans", reply);
                } else {
                    toast.error(i18n.global.t('alert.error.cantLoadPlans'));
                }
            })
            .catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        },
        async getSession({commit, dispatch}, data) {
            commit('setProcessingOrder', true);
            API.post(
                'billingSession',
                data.id,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                        ...data
                    }
                }
            ).then( async (reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    commit('setSession', reply);
                } else {
                    toast.error(i18n.global.t('alert.error.cantLoadStripeSession'));
                    commit('setSession', false);
                }
                commit("setProcessingOrder", false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setProcessingOrder", false);
                    commit('setSession', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurredWithSession'));
                    throw err;    
                }
            })
        },
        async getCoupon({commit, dispatch}, data){
            commit('setCoupon', {});
            API.post(
                'billingCoupon',
                data.id,
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                        ...data
                    }
                }
            ).then( async (reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    commit('setAppliedCoupon', true);
                    commit('setCouponError', 0);
                    commit('setCoupon', reply);
                } else {
                    commit('setAppliedCoupon', false);
                    commit('setCouponError', 1);
                    commit('setCoupon', null);
                }
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setAppliedCoupon', false);
                    commit('setCouponError', 2);
                    commit('setCoupon', null);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            });
        },
        async getCustomer({commit, dispatch}){
            API.get('billingCustomer', '', {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {}
            })
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    commit("setCustomer", reply);
                } else {
                    commit("setCustomer", false);
                }
            })
            .catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setCustomer", false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        },
        async updateCustomer({dispatch}){
            API.patch('billingCustomer', '', {
                headers: {
                    Authorization: await getSessionToken(),
                },
                body: {}
            })
            .then((reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    console.log(reply);
                } else {
                    console.log(reply);
                }
            })
            .catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                if (!err.toString().includes('404')) {
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;
                }
            })
        },
        setGeoPluginData({commit}, data){
            commit('setGeoPluginData', data)
        },
        async requestCancellation({commit, dispatch}){
            API.del(
                'deleteSubscription',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken()
                    },
                    body: {
                    }
                }
            ).then( async (reply) => {
                if (reply) {
                    commit("setCancelled", true);
                    commit('setCancellationErrors', false);
                } else {
                    toast.error(i18n.global.t('alert.error.errorWithCancellation'));
                    commit('setCancellationErrors', true);
                    commit('setCancelled', false);
                }
            }).catch((err) => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setCancelled', false);
                    commit('setCancellationErrors', true);
                    toast.error(i18n.global.t('alert.error.errorWithCancellation'));    
                }
            })
        },
        async getPortalSession({commit, dispatch}, data) {
            commit('setRequestingPortalSession', true);
            API.post(
                'portalSession',
                '',
                {
                    headers: {
                        Authorization: await getSessionToken(),
                    },
                    body: {
                        ...data
                    }
                }
            ).then( async (reply) => {
                if (reply && Object.keys(reply).length > 0) {
                    commit('setPortalSession', reply);
                } else {
                    commit('setPortalSession', false);
                }
                commit("setRequestingPortalSession", false);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit("setRequestingPortalSession", false);
                    commit('setPortalSession', false);
                    toast.error(i18n.global.t('alert.error.anErrorHasOccurred'));
                    throw err;    
                }
            })
        }
    },
    getters: {
        getPricingPlans: state => {
            return state.pricingPlans;
        },
        getProcessingOrder: (state) => {
            return state.processingOrder;
        },
        getSubscriptionStatus: (state) => {
            return state.subscriptionStatus;
        },
        getSession: (state) => {
            return state.session;
        },
        getAppliedCoupon: (state) => {
            return state.appliedCoupon;
        },
        getCoupon: (state) => {
            return state.coupon;
        },
        getCouponError: (state) => {
            return state.couponError;
        },
        getCustomer: (state) => {
            return state.customer;
        },
        getGeoPluginData: (state) => {
            return state.geoPluginData;
        },
        getCancelled: (state) => {
            return state.cancelled;
        },
        getCancellationErrors: (state) => {
            return state.cancellationErrors;
        },
        getHasSubscriptions: (state) => {
            return state.hasSubscriptions;
        },
        getPortalSession: (state) => {
            return state.portalSession;
        },
        getRequestingPortalSession: (state) => {
            return state.requestingPortalSession;
        }
    }
}

export default billing