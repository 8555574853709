<template>
    <div class="fs-medium">
        <i18n-t :keypath="msg" tag="span">
            <template v-slot:action>
                <router-link v-if="url != null" :to="url" class="text-white poppins-bold">{{$t(actionMsg)}}</router-link>
                <span v-else @click="emitAction()" class="text-white text-underline poppins-bold pointer">{{$t(actionMsg)}}</span> 
            </template>
        </i18n-t>    
    </div>
</template>

<script lang="js">
export default {
    data() {
        return {}
    },
    props: {
        url: Object,
        action: String,
        actionMsg: String,
        msg: String
    },
    methods: {
        emitAction() {
            this.$emit(this.action);
        }
    }
}
</script>

<style lang="scsss">
</style>